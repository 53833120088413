.game_container
{
  /* background-color: #e3e3e3; */
  max-width: 90%;
  margin: 0 auto;

  /* padding: 100px 50px 20px 50px; */

  /* padding:50px; */
  /* margin: 20px; */
}
.wheel_contain{
  max-width: 100% !important;
  /* padding: 100px 20px 20px 20px !important; */
}
.rolldice{
    padding: 40px 50px 20px 50px !important;
}
.styles_dice
{
  display: grid;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: min-content;
    margin: 0px auto 0px auto;
    /* width: 100%;
    max-width: 50%;
    min-width: 50%; */
}
.dicefields{
    width: 100%;
    max-width: 45%;
    min-width: 45%;
    margin: auto;
}
.dice_box
{
  display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 85px;
    height: 85px;
    background-color: var(--inputclr);
    border: 4px solid #171518;
    border-radius: 16px;
    background-image: url("../assets/game/plain.svg");
    background-size: cover;
    background-position: 50%;
    transition: all .25s;
    padding: 14px;
    cursor: pointer;
}
.dice_box:hover
{
  background-image: none;
}
.dice_box[data-state="selected"]
{
  background-color: var(--inputclr);
    border-color: var(--inputclr);
    background-image: url("../assets/diceback.png");

}
.dice_box[data-value="2"]
{
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}
.dice_box[data-value="3"]
{
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}
.dice_box[data-value="4"]
{
  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
.dice_box[data-value="5"]
{
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;  
}
.dice_box[data-value="6"]
{
  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
}
.dice_dot
{
  position: relative;
    z-index: 1;
    border-radius: 100%;
    background-color: #fff;
    width: 10px;
    height: 10px;
}

.dice_dot_two_2
{
  grid-row: 3;
    grid-column: 3;
    align-self: end;
    justify-self: end;
}

.dice_dot_three_2
{
  grid-row: 2;
    grid-column: 2;
    justify-self: center;
    align-self: center;
}
.dice_dot_three_3
{
  grid-row: 3;
    grid-column: 3;
    justify-self: end;
    align-self: end;
}
.dice_dot_four_1
{
  justify-self: start;
    align-self: start;
}
.dice_dot_four_2
{
  justify-self: end;
    align-self: start;
}
.dice_dot_four_3
{
  justify-self: start;
    align-self: end;
}
.dice_dot_four_4
{
  justify-self: end;
  align-self: end;
}
.dice_dot_five_1
{
  justify-self: start;
    align-self: start;
}
.dice_dot_five_2
{
  justify-self: end;
    align-self: start;
    grid-column: 3;
}
.dice_dot_five_3
{
  justify-self: center;
    align-self: center;
    grid-row: 2;
    grid-column: 2;
}
.dice_dot_five_4
{
  justify-self: start;
    align-self: end;
    grid-row: 3;
    grid-column: 1;
}
.dice_dot_five_5
{
  justify-self: end;
    align-self: end;
    grid-column: 3;
    grid-row: 3;
}
.dice_dot_six_1
{
  justify-self: start;
  align-self: start;
}
.dice_dot_six_2
{
  justify-self: end;
  align-self: start;

}
.dice_dot_six_3
{
  justify-self: start;
  align-self: start;

}
.dice_dot_six_4
{
  justify-self: end;
  align-self: start;

}
.dice_dot_six_5
{
  justify-self: start;
  align-self: start;

}
.dice_dot_six_6
{
  justify-self: end;
  align-self: start;

}


.btn_danger
{
  background-color: var(--themebtnclr);
  border:2px solid var(--themebtnclr);
  color:#fff;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}
.btn_danger:hover
{
  background-color: #363636;
  border:2px solid #363636;
  color: #fff !important;
}
.dice_btn
{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:575px)
{
  .dot-container
  {
    grid-gap:11.33px 15.33px !important; 
  }
  .dice .faces_dice
  {
    top:-8px !important;
  }
  .dice .face-6.faces_dice
  {
    top:-5px !important;
  }
  .dice_box
  {
    padding: 6px !important;
  }
    .gamesdices{
        margin-top: 30px;
    }
    .gamesdices .btn_danger {
       margin-top: 20px;
    }
    .dice-container
    {
        margin: 4px !important;
    }
  .game_container
  {
    padding:0px !important;
    margin: 5px !important;
    min-width: 100%;
  }
}

@media only screen and (min-width:576px)
{
  .dice .faces_dice
  {
    top: -10px !important;
  }
  .dice .faces_dice.face-6
  {
    top:-6px !important;
  }
  .dot-container
  {
    grid-gap: 11.33px 15.33px !important;
  }
}
/* DICE GAME */
/* 160px: 160px;
28.8px: 160px*.18; */


.dice-container {
  height: 85px;
  width: 85px;
  perspective: 1000px;
  border-radius: 16px;
  overflow: hidden;
  /* border:4px solid #363636; */
  border:4px solid var(--inputclr);
  margin: 20px;
}

.dice {
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;

  transition: all 200ms;
}
.dice.face-1 {
    transform: rotateX(0);
  }
  .dice.face-2 {
    transform: rotateY(90deg);
  }
  .dice.face-3 {
    transform: rotateX(-90deg);
  }
  .dice.face-4 {
    transform: rotateX(90deg);
  }
  .dice.face-5 {
    transform: rotateY(-90deg);
  }
  .dice.face-6 {
    transform: rotateX(-180deg);
  }

.dot-container {
  display: grid;
  grid-template-columns: repeat(3, 10px);
  grid-template-rows: repeat(3, 10px);
  grid-gap: 15.33px 15.33px;
}

.dot {
  background-color: #fff;
  border-radius: 50%;
}

.dice .faces_dice{
    position: absolute;
    width: 95px;
    left: -8px;
    height: 95px;
    top: -6px;
    /* outline: 2px solid #00bbff;
    outline-offset: -2px; */
    /* background-color: #363636; */
    background-color: var(--inputclr);
    /* border: 4px solid #363636; */
    border-radius: 16px;
    /* background-image: url(./assets/images/plain.svg); */
    background-image: url("../assets/diceback.png");

    background-size: cover;
    background-position: 50%;
    transition: all .25s;
    /* background-color: rgba(255, 255, 255, 0.9); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .face-1 {
    transform: translateX(0) translateY(0) translateZ(80px);
  }
  .face-1 .dot-container .dot {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }

  .face-3 {
    transform: translateX(0) translateY(-50%) translateZ(0) rotateX(90deg);
  }
  .face-3 .dot-container .dot:nth-child(1) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .face-3 .dot-container .dot:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
      .face-3 .dot-container .dot:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }

  .face-2 {
    transform: translateX(-50%) translateY(0%) translateZ(0) rotateY(90deg);
  }
    
  .face-2 .dot-container .dot:nth-child(1) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .face-2 .dot-container .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
    
  

  .face-4 {
    transform: translateX(0) translateY(50%) translateZ(0) rotateX(90deg);
  }
  .face-4  .dot-container .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .face-4  .dot-container .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .face-4  .dot-container .dot:nth-child(3) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .face-4  .dot-container .dot:nth-child(4) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }

  .face-5 {
    transform: translateX(50%) translateY(0%) translateZ(0) rotateY(90deg);
  }
  .face-5 .dot-container .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .face-5 .dot-container .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .face-5 .dot-container .dot:nth-child(5) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
      .face-5 .dot-container .dot:nth-child(3) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .face-5 .dot-container .dot:nth-child(4) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }

  .face-6 {
    transform: translateX(0) translateY(0) translateZ(-80px);
  }
  .face-6  .dot-container .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .face-6  .dot-container .dot:nth-child(2) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .face-6  .dot-container .dot:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .face-6  .dot-container .dot:nth-child(4) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
      .face-6  .dot-container .dot:nth-child(5) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .face-6  .dot-container .dot:nth-child(6) {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
      }

.button-container {
  display: flex;
  justify-content: center;
}

.dan-btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 10px;
  border-radius: 3px;
  background-color: #dddddd;
  color: #444;
  cursor: pointer;
 
}
.dan-btn[disabled] {
  opacity: 0.4;
  /* cursor: no-drop; */
}
.dan-btn--default {
  background-color: #dddddd;
  color: #444;
}

.dice_btn
{
  margin-top: 10px;
}

.dice_flex
{
  max-width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

/* END DICE GAME */

/* COIN GAME */
.coin_img_section
{
  margin: 0 auto;
  text-align: center;
}
.coin_image
{
  max-width: 185px;
}
.btn_grp_border
{
  border: 2px solid #b2b3b6;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    padding: 3px;
    transition: .5s;
    width: 215px;
    margin: 0 auto;
}
.btn_grp_border .btn
{
  background-color: transparent;
  border:none;
  color:#000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 18px;
  cursor: pointer;
  border-radius: 8px;
}
.btn_grp_border .btn img
{
  max-width: 20px;
}
.btn_grp_border .btn.active,.btn_grp_border .btn:hover
{
  background-color: #c8c8c8;
  color:#000;
}

.img_sahdow 
  {
    position: absolute;
    bottom: 25px;
    left:calc(50% - 43px);
  }
    /* .front {
      transform: perspective(600px) rotateY(0deg);
  }
  .back{
       transform: perspective(600px) rotateY(-180deg); 
  } */



  #coin {
    position: relative;
    margin: 0 auto;
    width: 185px;
    height: 185px;
    /* overflow: hidden; */
    cursor: pointer;
  }
  #coin div {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  
  }
  /* .side-a {
    background-color: #bb0000;
  }
  .side-b {
    background-color: #3e3e3e;
  } */
/* 
  .side-b img{
    transform: rotateY(360deg);
    -moz-transform: rotateY(180deg);
  } */
  .coin_img_section
  {
    position: relative;
    min-height: 225px;
  }
  #coin {
    transition: -webkit-transform 1s ease-in;
    transition:   transform 1s ease-in;
    transition:   -moz-transform 1s ease-in;


    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;

    transform-style: preserve-3d;

  }
  #coin div {
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;

    backface-visibility: hidden;

    
  }
  .side-a {
    z-index: 100;
  }
  .side-b {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);


  
  }
  
  @-moz-keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @-moz-keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }


  @-webkit-keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @-webkit-keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }


  @keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }

  #coin.tails {
    -webkit-animation: flipHeads 3s ease-out forwards;
    -moz-animation: flipHeads 3s ease-out forwards;
      -o-animation: flipHeads 3s ease-out forwards;
         animation: flipHeads 3s ease-out forwards;

         animation-name: flipHeads;
         animation-duration: 3s;
         animation-timing-function: ease-out;
         animation-fill-mode:  forwards;
  }
  #coin.heads {
    -webkit-animation: flipTails 3s ease-out forwards;
    -moz-animation: flipTails 3s ease-out forwards;
      -o-animation: flipTails 3s ease-out forwards;
         animation: flipTails 3s ease-out forwards;

         animation-name: flipTails;
         animation-duration: 3s;
         animation-timing-function: ease-out;
         animation-fill-mode:  forwards;
  }
  

  
  .side-a
  {
    background-image: url("../assets/game/tails-img.png");
    background-size: contain;
    -webkit-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    -moz-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    box-shadow: 1px -3px 3px 0 rgb(189 189 189) inset;
    
  }

    
  .side-b
  {
    background-image: url("../assets/game/heads-img.png");
    background-size: contain;
    -webkit-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    -moz-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
  }

  .coin_flex
  {
    flex-direction: column;
  }
  .coin_flex p
  {
    text-align: center;
  }
  .coin_flex span{
    color:#000 !important;
    font-weight: 700;
  }
  .backsideimg.img_con_backside
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width:575px)
  {
    .img_con_backside
    {
      background-position: center;
    }
    .btn_grp_border .btn
    {
      padding: 5px 11px !important;
    }
    .btn_grp_border
    {
      width: 190px !important;
    }
  }

  @media only screen and (min-width:768px) and (max-width:991px)
  {
    .img_con_backside .coin_img_section 
    {
      margin-top: -120px !important;
    }
  }

  @media only screen and (min-width:992px) and (max-width:1199px)
  {
    .img_con_backside .coin_img_section 
    {
      margin-top: -70px !important;
    }
  }

  .tosscoinbtn_posd
  {
    position: absolute;
    bottom: -5px;
  }
  /* END COIN */

  /* kr css */
  .multiplier_content{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin-top: 30px;
  }
.multiplier_title{
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.multiplier_box{
  border: 1px solid var(--inputclr);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--inputclr);
}
.multiplier_box p{
  margin: 0;
}
.multiplier_value{
  color: var(--whtclr);
  font-weight: 600;
}

  /* end of kr css */


  .cur_pointer_copy
  {
    cursor: pointer;
  }