/* COIN GAME */
.coin_img_section
{
  margin: 0 auto;
  text-align: center;
}
.coin_image
{
  max-width: 185px;
}
.btn_grp_border
{
  border: 2px solid var(--black);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    padding: 3px;
    transition: .5s;
    width: 215px;
    margin: 0 auto;
    background: var(--black);
}
.btn_grp_border .btn
{
  background-color: transparent;
  border:none;
  color:var(--whtclr);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px 20px; */
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 8px;
}

.btn_grp_border .btn img
{
  max-width: 20px;
}
.btn_grp_border .btn.active,.btn_grp_border .btn:hover
{
  background-color: var(--inputclr);
  color:var(--whtclr);
}

.img_sahdow 
  {
    position: absolute;
    bottom: 25px;
    left:calc(50% - 43px);
  }
    /* .front {
      transform: perspective(600px) rotateY(0deg);
  }
  .back{
       transform: perspective(600px) rotateY(-180deg); 
  } */



  #coin {
    position: relative;
    margin: 0 auto;
    width: 185px;
    height: 185px;
    /* overflow: hidden; */
    cursor: pointer;
  }
  #coin div {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  
  }
  /* .side-a {
    background-color: #bb0000;
  }
  .side-b {
    background-color: #3e3e3e;
  } */
/* 
  .side-b img{
    transform: rotateY(360deg);
    -moz-transform: rotateY(180deg);
  } */
  .coin_img_section
  {
    position: relative;
    min-height: 225px;
  }
  #coin {
    transition: -webkit-transform 1s ease-in;
    transition:   transform 1s ease-in;
    transition:   -moz-transform 1s ease-in;


    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;

    transform-style: preserve-3d;

  }
  #coin div {
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;

    backface-visibility: hidden;

    
  }
  .side-a {
    z-index: 100;
  }
  .side-b {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
  
  }
  
  @-moz-keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @-moz-keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }


  @-webkit-keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @-webkit-keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }


  @keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
  }
  @keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
  }

.form-control.rollunder_input{
   background-color: var(--backclr) !important;
   border-color: var(--backclr) !important;
  }
  .win_grp{
    background-color: #0048C3 !important;
    border-color: #0048C3 !important;
  }
  .multi_3{
    background-color: #344158 !important;
    border-color: #344158 !important;
  }
  #coin.tails {
    -webkit-animation: flipHeads 5s ease-out forwards;
    -moz-animation: flipHeads 5s ease-out forwards;
      -o-animation: flipHeads 5s ease-out forwards;
         animation: flipHeads 5s ease-out forwards;

         animation-name: flipHeads;
         animation-duration: 5s;
         animation-timing-function: ease-out;
         animation-fill-mode:  forwards;
  }
  #coin.heads {
    -webkit-animation: flipTails 5s ease-out forwards;
    -moz-animation: flipTails 5s ease-out forwards;
      -o-animation: flipTails 5s ease-out forwards;
         animation: flipTails 5s ease-out forwards;

         animation-name: flipTails;
         animation-duration: 5s;
         animation-timing-function: ease-out;
         animation-fill-mode:  forwards;
  }
  

  
  .side-a
  {
    background-image: url("../assets/tails-img.png");
    background-size: contain;
    -webkit-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    -moz-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    box-shadow: 1px -3px 3px 0 rgb(189 189 189) inset;
    
  }

    
  .side-b
  {
    background-image: url("../assets/heads-img.png");
    background-size: contain;
    -webkit-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    -moz-box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
    box-shadow: 1px -3px 4px 0 rgb(189 189 189) inset;
  }

  .coin_flex
  {
    flex-direction: column;
  }
  .coin_flex p
  {
    text-align: center;
  }
  .coin_flex span{
    color:#000 !important;
    font-weight: 700;
  }

  /* END COIN */


  /* RPS */
.selected_card_rps.card_rps
  {
    background-color: var(--black) !important;
    color: #ffa60e;
    border-bottom: 8px solid var(--inputclr);
    cursor: default;
   
  }
  .card_rps
  {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 10px;
    height: min-content;
    width: 135px;
    height: 135px;
    min-width: 135px;
    min-height: 135px;
    z-index: 1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: default;
    cursor: pointer;
  }
  .flex_row_1
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 70%;
    margin: 0 auto;
  }
  .flex_row_2
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin: 20px auto;
  }

  .checkbox_circle[data-state="selected"]
  {
    width: 12px;
    height: 12px;
    border-color: #fff;
    border-width: 6px;
    background-color: #ffa60e;
  }
  .checkbox_circle
  {
    border: 2px solid #68758c;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #000;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @media only screen and (max-width:575px)
  {
    .felx_mob_div_ew
    {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bucket
    {
      margin-left: 1px !important;
      margin-right: 1px !important;
    }
    .rps_bg img
    {
      /* max-width: 100px; */
      max-width: 50px;
    }
    .flex_row_1,.flex_row_2
    {
      max-width: 100%;
    }
    .card_rps
    {
      /* width: 95px;
    height: 95px;
    min-width: 95px;
    min-height: 95px */

    /* width: 70px;
    height: 80px;
    min-width: 70px;
    min-height: 80px; */

    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    }
  }


  @media only screen and (max-width: 350px) {
.card_rps {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
}

  }

  @media only screen and (min-width:576px) and (max-width:767px)
  {
    .rps_bg img
    {
      max-width: 170px;
    }
    .flex_row_1,.flex_row_2
    {
      max-width: 100%;
    }
   
  }

  @media only screen and (min-width:768px) and (max-width:991px)
  {
    .range_input_div.without_margin_div
    {
      display: block !important;
      margin-top: 80px !important;
    }
    .rps_bg img
    {
      max-width:100px;
    }
    .flex_row_1,.flex_row_2
    {
      max-width: 100%;
    }
   
  }

  @media only screen and (min-width:992px) and (max-width:1199px)
  {
    .range_input_div div
  {
    min-width: 170px !important;
  }
    .range_input_div.without_margin_div
    {
      display: block !important;
      margin-top: 80px !important;
    }
    .card_rps{
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
    }
    .rps_bg img
    {
      max-width:160px;
    }
    .flex_row_1,.flex_row_2
    {
      max-width: 100%;
    }
   
  }
  .img_ques
  {
    filter: brightness(1) invert(0.5);
  }

  /* END RPS */

  /* WHEEL */
  .badge
  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--inputclr);
    height: 25px;
    padding: 0 10px;
    border-radius: 30px;
    color: var(--whtclr);
    font-weight: 700;
    font-size: 12px;
  }
  .bange_grey.active
  {
    color:#8e98ab;
  }
  .bange_grey.active .bet_cirle
  {
    border-color:#8e98ab;

  }

  .bange_yellow.active
  {
    color:#ffa60e;
  }
  .bange_yellow.active .bet_cirle
  {
    border-color:#ffa60e;

  }

  .bange_blue.active
  {
    color:#1569b8;
  }
  .bange_blue.active .bet_cirle
  {
    border-color:#1569b8;

  }

  .bange_green.active
  {
    color:#27bf3f;
  }
  .bange_green.active .bet_cirle
  {
    border-color:#27bf3f;

  }

  .bange_red.active
  {
    color:#d9113a;
  }
  .bange_red.active .bet_cirle
  {
    border-color:#d9113a;

  }

  .bet_cirle
  {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    border: 3px solid var(--whtclr);
  }
  .flex_row_badge
  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 30px;
  }
  .wheel_bet_title
  {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
  .card_body_flex
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card.card_wheel
  {
    min-width: 150px;
    background: var(--black) !important;
    border-radius: 5px;
    height: 100%;
    transition: height .3s ease-in;
    cursor: pointer;
  }
  .card_header_wheel
  {
    position: relative;
    padding: 10px 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
  }
  .wheel_multiplier_text
  {
    font-size: 13px;
    font-weight: 600;
    color:#fff;
  }
  .card_body_flex
  {
    padding: 20px 10px;
  }
  .card_body_flex span
  {
    color:#68758c;
    font-weight: 600;
  }
  .card_body_flex svg
  {
    margin-right: 5px;
  }
  .card_grey_wheel
  {
    background-color: #283449;
  }
  .card_grey_wheel::before
  {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 5px 5px 0 0;
    top: 0;
    left: 0;
    background-color: #68758c;
  }

  .card_blue_wheel
  {
    background-color: #117bd966;
  }
  .card_blue_wheel::before
  {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 5px 5px 0 0;
    top: 0;
    left: 0;
    background-color: #117bd9;
  }

  .card_green_wheel
  {
    background-color: #37dd4a66;
  }
  .card_green_wheel::before
  {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 5px 5px 0 0;
    top: 0;
    left: 0;
    background-color: #37dd4a;
  }
  .card_red_wheel
  {
    background-color: #d9113a66;
  }
  .card_red_wheel::before
  {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 5px 5px 0 0;
    top: 0;
    left: 0;
    background-color: #d9113a;
  }



.wheel_div
{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

  

.wheel_div .container_wheel{
	width:280px;
	height:280px;
	background-color: #ccc;
	border-radius:50%;
	border:15px solid var(--whtclr);
	position: relative;
	overflow: hidden;
	transition: 2s;
}
.wheel_div .container_wheel::before
{
  content: "";
  background: url("../assets/center.svg");
  width: 200px;
  height: 200px;
  position: absolute;
  top:33px;
  left:33px;
  background-repeat: no-repeat;
  z-index: 2;
}
.wheel_div .container_wheel div{
	height:50%;
	width:15px;
	position: absolute;
	clip-path: polygon(100% 0 , 50% 100% , 0 0 );
	transform:translateX(-50%);
	transform-origin:bottom;
	text-align:center;
	display:flex;
	align-items: center;
	justify-content: center;
	font-weight:bold;
	font-family:sans-serif;
	color:#fff;
	left:117px;
  z-index: 2;
    font-size: 0px;
}

.wheel_div .container_wheel .one{
  /* background: linear-gradient(to bottom, #3f51b5 20%, #ccc 20%); */
	background-color: #273245;
	left:50%;
}
.wheel_div .container_wheel .two{
  /* background: linear-gradient(to bottom, #ff9800 20%, #ccc 20%); */

	background-color: #1569b8;
	transform: rotate(7.2deg);
}
.wheel_div .container_wheel .three{
	background-color: #273245;
	transform:rotate(14.4deg);
}
.wheel_div .container_wheel .four{
	background-color: #27bf3f;
	transform: rotate(21.6deg);
}
.wheel_div .container_wheel .five{
	background-color: #273245;
	transform: rotate(28.8deg);
}
.wheel_div .container_wheel .six{
	background-color: #1569b8;
	transform: rotate(36deg);
}
.wheel_div .container_wheel .seven{
	background-color: #273245;
	transform: rotate(43.2deg);
}
.wheel_div .container_wheel .eight{
	background-color: #1569b8;
	transform: rotate(50.4deg);
}
.wheel_div .container_wheel .nine{
	background-color: #273245;
	transform: rotate(57.6deg);
}
.wheel_div .container_wheel .ten{
	background-color: #27bf3f;
	transform: rotate(64.8deg);
}
.wheel_div .container_wheel .eleven{
	background-color: #273245;
	transform:rotate(72deg);
}
.wheel_div .container_wheel .twelve{
	background-color: #1569b8;
	transform: rotate(79.2deg);
}
.wheel_div .container_wheel .thirteen{
	background-color: #273245;
	transform: rotate(86.4deg);
}
.wheel_div .container_wheel .fourteen{
	background-color: #1569b8;
	transform: rotate(93.6deg);
}
.wheel_div .container_wheel .fifteen{
	background-color: #273245;
	transform: rotate(100.8deg);
}
.wheel_div .container_wheel .sixteen{
	background-color: #27bf3f;
	transform: rotate(108deg);
}
.wheel_div .container_wheel .seventeen{
	background-color: #273245;
	transform: rotate(115.2deg);
}
.wheel_div .container_wheel .eighteen{
	background-color: #1569b8;
	transform: rotate(122.4deg);
}
.wheel_div .container_wheel .nineteen{
	background-color: #273245;
	transform:rotate(129.6deg);
}
.wheel_div .container_wheel .twenty{
	background-color: #27bf3f;
	transform: rotate(136.8deg);
}
.wheel_div .container_wheel .twentyone{
	background-color: #273245;
	transform: rotate(144deg);
}
.wheel_div .container_wheel .twentytwo{
	background-color: #27bf3f;
	transform: rotate(151.2deg);
}
.wheel_div .container_wheel .twentythree{
	background-color: #273245;
	transform: rotate(158.4deg);
}
.wheel_div .container_wheel .twentyfour{
	background-color: #1569b8;
	transform: rotate(165.5deg);
}

.wheel_div .container_wheel .twentyfive{
	background-color: #273245;
	transform: rotate(172.8deg);
}
.wheel_div .container_wheel .twentysix{
	background-color: #1569b8;
	transform: rotate(180deg);
}
.wheel_div .container_wheel .twentyseven{
	background-color: #273245;
	transform:rotate(187.2deg);
}
.wheel_div .container_wheel .twentyeight{
	background-color: #27bf3f;
	transform: rotate(194.4deg);
}
.wheel_div .container_wheel .twentynine{
	background-color: #273245;
	transform: rotate(201.6deg);
}
.wheel_div .container_wheel .thirty{
	background-color: #1569b8;
	transform: rotate(208.8deg);
}
.wheel_div .container_wheel .thirtyone{
	background-color: #273245;
	transform: rotate(216deg);
}
.wheel_div .container_wheel .thirtytwo{
	background-color: #27bf3f;
	transform: rotate(223.2deg);
}
.wheel_div .container_wheel .thirtythree{
	background-color: #273245;
	transform: rotate(230.4deg);
}
.wheel_div .container_wheel .thirtyfour{
	background-color: #1569b8;
	transform: rotate(237.6deg);
}
.wheel_div .container_wheel .thirtyfive{
	background-color: #273245;
	transform:rotate(244.8deg);
}
.wheel_div .container_wheel .thirtysix{
	background-color: #1569b8;
	transform: rotate(252deg);
}
.wheel_div .container_wheel .thirtyseven{
	background-color: #273245;
	transform: rotate(259.2deg);
}
.wheel_div .container_wheel .thirtyeight{
	background-color: #27bf3f;
	transform: rotate(266.4deg);
}
.wheel_div .container_wheel .thirtynine{
	background-color: #273245;
	transform: rotate(273.6deg);
}
.wheel_div .container_wheel .fourty{
	background-color: #1569b8;
	transform: rotate(280.8deg);
}
.wheel_div .container_wheel .fourtyone{
	background-color: #273245;
	transform: rotate(288deg);
}
.wheel_div .container_wheel .fourtytwo{
	background-color: #1569b8;
	transform: rotate(295.2deg);
}
.wheel_div .container_wheel .fourtythree{
	background-color: #273245;
	transform:rotate(302.4deg);
}
.wheel_div .container_wheel .fourtyfour{
	background-color: #27bf3f;
	transform: rotate(309.6deg);
}
.wheel_div .container_wheel .fourtyfive{
	background-color: #273245;
	transform: rotate(316.8deg);
}
.wheel_div .container_wheel .fourtysix{
	background-color: #1569b8;
	transform: rotate(324deg);
}
.wheel_div .container_wheel .fourtyseven{
	background-color: #273245;
	transform: rotate(331.2deg);
}
.wheel_div .container_wheel .fourtyeight{
	background-color: #1569b8;
	transform: rotate(338.4deg);
}
.wheel_div .container_wheel .fourtynine{
	background-color: #273245;
	transform: rotate(345.6deg);
}
.wheel_div .container_wheel .fifty{
	background-color: #a90e36;
	transform: rotate(352.8deg);
}

.wheel_div .container_wheel .one
{
  /* max-width: 7.2% !important; */
  /* z-index: 1; */
}
.arrow{
	position: absolute;
	top:0;
	left:50%;
	transform: translateX(-50%);
	color:#fff;
}

.arrow::before{
	content:"\1F817";
	font-size:50px;
}

#spin{
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	z-index:10;
	background-color: #2b3950;
    text-transform: uppercase;
    border: 8px solid #1d2738;
    font-weight: bold;
    font-size: 14px;
    color: #a2a2a2;
    width: 70px;
    height: 70px;
	font-family: sans-serif;
	border-radius:50%;
	cursor: pointer;
	outline:none;
	letter-spacing: 1px;
}
#wheel_cursor
{
  width: 54px;
    height: 68px;
    /* width: 2px;
    height: 34px;
    background-color: yellow; */
    background: url(../assets/cursor.svg);
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
    z-index: 9;
}

.wheel_div .container_wheel div::before
{
  content: "";
    height: 10px;
    position: absolute;
    top: 0px;
    width: 15px;
}

.wheel_div .container_wheel div::after
{
  content: "";
    height: 6px;
    position: absolute;
    top: 26px;
    width: 15px;
}
.wheel_div .container_wheel .grey::before,
.wheel_div .container_wheel .grey::after
{
background-color: #39465b;
}


.wheel_div .container_wheel .blue::before,
.wheel_div .container_wheel .blue::after
{
background-color: #4c749b;
}


.wheel_div .container_wheel .green::before,
.wheel_div .container_wheel .green::after
{
background-color: #3baf59;
}

.wheel_div .container_wheel .red::before,
.wheel_div .container_wheel .red::after
{
background-color: #962a4e;
}

.wheel_div .container_wheel .grey::after

{
  box-shadow:-1px -4px 1px #4b5566;

}

.wheel_div .container_wheel .blue::after

{
  box-shadow:-1px -4px 1px #457fb7;

}


.wheel_div .container_wheel .green::after

{
  box-shadow:-1px -4px 1px #5cc376;

}


.wheel_div .container_wheel .red::after

{
  box-shadow:-1px -4px 1px #c15874;

}
.text_div_wheel p
{
margin-bottom: 0px !important;
margin-top: 0px !important;
color: #fff;
    font-size: 14px;
    font-weight: 700;
    background: #000000b3;
    padding: 4px 6px;
    border-radius: 4px;
}
.text_div_wheel
{
  position: absolute;
  z-index: 3;
}

.selected_card_wheel
{
  /* border:2px solid #ffa60e; */
  /* box-shadow:0px -1px 11px 12px #fea530c7; */
  box-shadow: 0px -1px 11px 12px #497fd036;
}
.text_div_wheel_win
{
  width: 200px;
    border-radius: 50%;
    height: 200px;
    position: absolute;
    z-index: 3;
}
.text_div_wheel_win.activebg_grey
{
  box-shadow:92px 170px 71px 59px inset rgb(40 52 73 / 84%);

}
.text_div_wheel_win.activebg_blue
{
  box-shadow:92px 170px 71px 59px inset rgb(20 67 115 / 84%);

}
.text_div_wheel_win.activebg_green
{
  box-shadow:92px 170px 71px 59px inset rgb(35 106 58 / 84%);

}
.text_div_wheel_win.activebg_red
{
  box-shadow:92px 170px 71px 59px inset rgb(100 25 51 / 84%);

}
.activebg_text
{
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color:#fff;
  font-weight: 700;
  min-width: 200px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 200px;
}
/* NEW  */

/* .mainbox {
  position: relative;
  width: 500px;
  height: 500px;
  text-align: center;
}

.mainbox:after {
  position: absolute;
  content: '';
  width: 32px;
  height: 32px;
  background: url('../img/arrow-wheel.png') no-repeat;
  background-size: 32px;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  border: 10px solid #fff;
  overflow: hidden;
  transition: all ease 5s;
} */

/* span {
  width: 50%;
  height: 50%;
  display: inline-block;
  position: absolute;
}

.span1 {
  clip-path: polygon(0 92%, 100% 50%, 0 8%);
  background-color: #fffb00;
  top: 120px;
  left: 0;
} */

/* .span2 {
  clip-path: polygon(100% 92%, 0 50%, 100% 8%);
  background-color: #ff4fa1;
  top: 120px;
  right: 0;
}

.span3 {
  clip-path: polygon(50% 0%, 8% 100%, 92% 100%);
  background-color: #ffaa00;
  bottom: 0;
  left: 120px;
} */

/* .span4 {
  clip-path: polygon(50% 100%, 92% 0, 8% 0);
  background-color: #22ff00;
  top: 0;
  left: 120px;
} */

/* .box1 .span3 b {
  transform: translate(-50%, -50%) rotate(-270deg);
}

.box1 .span1 b,
.box2 .span1 b {
  transform: translate(-50%, -50%) rotate(185deg);
} */
/* 
.box2 .span3 b {
  transform: translate(-50%, -50%) rotate(90deg);
}

.box1 .span4 b,
.box2 .span4 b {
  transform: translate(-50%, -50%) rotate(-85deg);
} */

/* .box2 {
  width: 100%;
  height: 100%;
  transform: rotate(-135deg);
} */

/* span b {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #001aff;
  color: #fff;
  box-shadow: 0 5px 20px #000;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
} */

/* .spin:active {
  width: 70px;
  height: 70px;
  font-size: 20px;
} */

.wheel_div.animate:after {
  animation: animateArrow 0.7s ease infinite;
}

@keyframes animateArrow {
  50% {
    right: -40px;
  }
}

#spinresult {
  padding: 1em;
  font-family: sans-serif;
  color: white;
}

.arrow-down {
  position: absolute;
  right: 50%;
  z-index: 9;
}

.arrow-down::before {
  position: absolute;
  left: -1em;
  content: "";
  border-top: 1em solid red;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
}
/* 
.box2
{
  transform: rotate(-135deg);
} */


/* plinko css */
div.chip {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: blue;
}
#plinko-board {
  position: relative;
  margin-top: 2em;
  width: 400px;
  max-width: 100%;

  height: 200px;
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/25877/board.png');
}
ul.buckets, ul.percents {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 !important;
  margin: 5px 0;
}
ul.buckets {
  bottom: -2em;
  border-bottom: 1px solid black;
}
ul.percents {
  bottom: -3.5em;
  padding: 0 !important;
}
ul.buckets li, ul.percents li {
  list-style: none;
  float: left;
  display: block;
  margin-left: 10.45%;
  width: 31px;
  text-align: center;
}
/* end plinko css */




/* Limbo css */
.drager_div
{
  max-width: 320px;
  margin: 0 auto;
  width: 320px;
  height: 320px;
  position: relative;
}
.limbo_ui_limbo__box
{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.drager_div
{
  background-color: var(--black);
  border-radius: 50%;
  padding: 20px;
  z-index: 2;
}
.bgwithlno
{
  position: relative;
  max-width: 320px;
  margin: 0 auto;

}
.shadowbg_div
{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #497fd08f;
    mix-blend-mode: normal;
    opacity: .3;
    filter: blur(22px);
    z-index: 0;
}
.logo_pigi
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 100px;
}
.drager_div code
{
  display: none;
}
/* end limbo css */




.progress {
  width: 220px;
  height: 220px;
  line-height: 220px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  /* position: relative; */
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border: 5px solid #efefef; */
  border:18px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 18px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: var(--inputclr)
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 110px;
  border-bottom-right-radius: 110px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 1rem;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 300;
}
.progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.progress[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.5s linear forwards;
}
.progress[data-percentage="1"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.5s linear forwards;
}
.progress[data-percentage="2"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.5s linear forwards;
}
.progress[data-percentage="3"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.5s linear forwards;
}
.progress[data-percentage="4"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="5"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.5s linear forwards;
}
.progress[data-percentage="6"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.5s linear forwards;
}
.progress[data-percentage="7"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.5s linear forwards;
}
.progress[data-percentage="8"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.5s linear forwards;
}
.progress[data-percentage="9"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.5s linear forwards;
}
.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.5s linear forwards;
}
.progress[data-percentage="11"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.5s linear forwards;
}
.progress[data-percentage="12"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.5s linear forwards;
}
.progress[data-percentage="13"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.5s linear forwards;
}
.progress[data-percentage="14"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.5s linear forwards;
}
.progress[data-percentage="15"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.5s linear forwards;
}
.progress[data-percentage="16"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.5s linear forwards;
}
.progress[data-percentage="17"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.5s linear forwards;
}
.progress[data-percentage="18"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.5s linear forwards;
}
.progress[data-percentage="19"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.5s linear forwards;
}
.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.5s linear forwards;
}
.progress[data-percentage="21"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.5s linear forwards;
}
.progress[data-percentage="22"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.5s linear forwards;
}
.progress[data-percentage="23"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.5s linear forwards;
}
.progress[data-percentage="24"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.5s linear forwards;
}
.progress[data-percentage="25"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.5s linear forwards;
}
.progress[data-percentage="26"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.5s linear forwards;
}
.progress[data-percentage="27"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.5s linear forwards;
}
.progress[data-percentage="28"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.5s linear forwards;
}
.progress[data-percentage="29"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.5s linear forwards;
}
.progress[data-percentage="31"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.5s linear forwards;
}
.progress[data-percentage="32"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.5s linear forwards;
}
.progress[data-percentage="33"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.5s linear forwards;
}
.progress[data-percentage="34"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.5s linear forwards;
}
.progress[data-percentage="35"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.5s linear forwards;
}
.progress[data-percentage="36"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.5s linear forwards;
}
.progress[data-percentage="37"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.5s linear forwards;
}
.progress[data-percentage="38"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.5s linear forwards;
}
.progress[data-percentage="39"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.5s linear forwards;
}
.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.5s linear forwards;
}
.progress[data-percentage="41"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.5s linear forwards;
}
.progress[data-percentage="42"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.5s linear forwards;
}
.progress[data-percentage="43"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.5s linear forwards;
}
.progress[data-percentage="44"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.5s linear forwards;
}
.progress[data-percentage="45"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.5s linear forwards;
}
.progress[data-percentage="46"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.5s linear forwards;
}
.progress[data-percentage="47"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.5s linear forwards;
}
.progress[data-percentage="48"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.5s linear forwards;
}
.progress[data-percentage="49"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s;
}

.progress[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s;
}

.progress[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s;
}

.progress[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s;
}

.progress[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s;
}

.progress[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.5s linear forwards 0.5s;
}

.progress[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.5s linear forwards 0.5s;
}

.progress[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.5s linear forwards 0.5s;
}

.progress[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.5s linear forwards 0.5s;
}

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.5s linear forwards 0.5s;
}

.progress[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.5s linear forwards 0.5s;
}

.progress[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.5s linear forwards 0.5s;
}

.progress[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.5s linear forwards 0.5s;
}

.progress[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.5s linear forwards 0.5s;
}

.progress[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.5s linear forwards 0.5s;
}

.progress[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.5s linear forwards 0.5s;
}

.progress[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.5s linear forwards 0.5s;
}

.progress[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.5s linear forwards 0.5s;
}

.progress[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.5s linear forwards 0.5s;
}

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.5s linear forwards 0.5s;
}

.progress[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.5s linear forwards 0.5s;
}

.progress[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.5s linear forwards 0.5s;
}

.progress[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.5s linear forwards 0.5s;
}

.progress[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.5s linear forwards 0.5s;
}

.progress[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.5s linear forwards 0.5s;
}

.progress[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.5s linear forwards 0.5s;
}

.progress[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.5s linear forwards 0.5s;
}

.progress[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.5s linear forwards 0.5s;
}

.progress[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.5s linear forwards 0.5s;
}

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.5s linear forwards 0.5s;
}

.progress[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.5s linear forwards 0.5s;
}

.progress[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.5s linear forwards 0.5s;
}

.progress[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.5s linear forwards 0.5s;
}

.progress[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.5s linear forwards 0.5s;
}

.progress[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.5s linear forwards 0.5s;
}

.progress[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.5s linear forwards 0.5s;
}

.progress[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.5s linear forwards 0.5s;
}

.progress[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.5s linear forwards 0.5s;
}

.progress[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.5s linear forwards 0.5s;
}

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.5s linear forwards 0.5s;
}

.progress[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.5s linear forwards 0.5s;
}

.progress[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.5s linear forwards 0.5s;
}

.progress[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.5s linear forwards 0.5s;
}

.progress[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.5s linear forwards 0.5s;
}

.progress[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.5s linear forwards 0.5s;
}

.progress[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.5s linear forwards 0.5s;
}

.progress[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.5s linear forwards 0.5s;
}

.progress[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.5s linear forwards 0.5s;
}

.progress[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.5s linear forwards 0.5s;
}

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.5s linear forwards 0.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg);
  }
}
@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg);
  }
}
@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg);
  }
}
@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg);
  }
}
@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg);
  }
}
@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg);
  }
}
@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg);
  }
}
@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg);
  }
}
@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg);
  }
}
@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg);
  }
}
@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg);
  }
}
@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg);
  }
}
@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg);
  }
}
@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg);
  }
}
@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg);
  }
}
@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg);
  }
}
@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg);
  }
}
@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg);
  }
}
@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg);
  }
}
@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg);
  }
}
@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg);
  }
}
@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg);
  }
}
@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg);
  }
}
@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg);
  }
}
@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg);
  }
}
@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg);
  }
}
@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg);
  }
}
@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg);
  }
}
@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg);
  }
}
@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg);
  }
}
@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg);
  }
}
@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg);
  }
}
@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg);
  }
}
@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg);
  }
}
@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg);
  }
}
@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg);
  }
}
@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg);
  }
}
@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg);
  }
}
@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg);
  }
}
@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg);
  }
}
@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg);
  }
}
@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

.anim_color
{
  filter: brightness(0.5);
  transition: all 0.5s ease-in-out;
}

.game_container_limbo {
  max-width: 90%;
  margin: 0 auto;
  /* padding: 35px 50px 20px 50px; */
  padding: 35px 50px 34% 50px;
}


@media only screen and (max-width:1199px)
{
    .flex_row_2 {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        justify-content: center;
    }
    .game_container_limbo {
      padding: 35px 50px 54% 50px;
    }
    
}

@media only screen and (max-width: 991px) and (min-width: 767px)  {
  .rps_game{
    padding: 100px 10px 20px 10px;
}
.card_rps {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
}
}
@media only screen and (max-width:767px)
{
    .flex_row_2 {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        justify-content: center;
    }

}


@media only screen and (max-width: 767px) and (min-width: 576px)  {
.rps_game{
    padding: 100px 10px 20px 10px;
}
.card_rps {
  width: 110px;
  height: 110px;
  min-width: 110px;
  min-height: 110px;
}
}

@media only screen and (max-width:575px)
{
  .rps_game{
    margin-top: 10% !important;
    max-width: 100% !important;
  }
    .coin_flip .btn_grp_border{
        width: 180px !important;
    }
.coin_flip .btn_grp_border .btn
{
    padding: 5px 10px !important;
}
.flex_row_badge {
    display: grid;
    gap: 5px;
    margin-top: 12%;
    grid-template-columns: auto auto auto;
}
.flex_row_2 {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    justify-content: center;
}
.rps_game .flex_row_2 {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.card_wheel {
    min-width: 190px;
}
}
@media only screen and (max-width:575px)  {
  .wheel_div .container_wheel div {
    left: 45% !important;
    }
    .wheel_div .container_wheel {
      width:200px !important;
      height: 200px !important;
    
    }
    .wheel_div .container_wheel::before {
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0px !important;
      left: 0px !important;
    }
    .text_div_wheel p {
      font-size: 12px !important;
    }
}
/* @media only screen and (max-width:450px)  {
  
    .wheel_div .container_wheel {
      width:200px !important;
      height: 190px !important;
    
    }
  } */


@media only screen and (max-width:575px)
{
  .game_container_limbo
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .wheel_div .container_wheel
  {
    border-width: 5px !important;
  }
}
 @media only screen and (max-width:450px)
{
.circ_div_li>div:first-child
  {
    margin-top: -45px;
    margin-left: -44px;
  }
  .bgwithlno
  {
    max-width: 270px;
  }
  .drager_div
  {
    max-width: 150px;
    width: 150px;
    height: 150px;
  }
  .progress
  {
    width: 180px;
    height: 180px;
    line-height: 180px;
  }
  .progress .progress-bar
  {
    border-width: 10px;
  }
  .bgwithlno
  {
    min-height: 300px;
  }
  
  .shadowbg_div
  {
    display: none;
  }
  /* .bgwithlno
  {
    max-width: 200px !important;
  }
  .drager_div
  {
    width: 200px !important;
    height: 200px !important;
  }
  .limbo_ui_limbo__box
  {
    max-width: 180px !important;
  }
  .circ_div_li>div>svg:first-child{
    max-width: 210px !important;
    margin-left: -25px;
    margin-top: -25px !important;
    max-height: 210px !important;
  }
  .drager_div
  {
    margin-left: -15px !important;
    margin-top: 25px !important;
  } */

  
}

@media only screen and (min-width:451px) and (max-width:767px)
{
  .drager_div
  {
    margin-left: -5%;
  }
  .shadowbg_div 
  {
    transform: translate(-55%,-50%);
  }
}

/* Moon */



  @media only screen and (max-width:575px)
{
  #randomrocket
  {
    min-width: 100% !important;
    font-size: 12px !important;
    padding: 10px;
    font-weight: 500;
    text-align: center;
    word-break: break-word;
  }
  #randomrocketx
  {
    top: 70px !important;
    left: 10px !important;
    color: #ffa60e;
    font-size: 30px !important;
  }
  
  .flex_row_badge.flex_row_badge_moon .badge
  {
    padding: 0 5px !important;
  }
  #currentMultiplier
  {
    left: 10% !important;
    top:10% !important;
  }
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  #randomrocket
  {
    min-width: 100% !important;
    font-size: 14px !important;
    padding: 10px;
    font-weight: 500;
    text-align: center;
    word-break: break-word;
  }
  #randomrocketx
  {
    top: 50px !important;
    left: 20px !important;
    color: #ffa60e;
    font-size: 30px !important;
  }
  #currentMultiplier
  {
    left: 0% !important;
    top:10% !important;
  }
  .flex_row_badge.flex_row_badge_moon {
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 12%;
    grid-template-columns: auto auto auto auto;
}
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .flex_row_badge.flex_row_badge_moon {
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 12%;
    grid-template-columns: auto auto auto auto;
}
#randomrocket
{
  min-width: 100% !important;
  font-size: 14px !important;
  padding: 10px;
  font-weight: 500;
  text-align: center;
  word-break: break-word;
}
  #randomrocketx
  {
    top: 45px !important;
    left: 20px !important;
    color: #ffa60e;
    font-size: 35px !important;
  }
  #currentMultiplier
  {
    left: 0% !important;
    top:10% !important;
  }
  .flex_row_badge.flex_row_badge_moon {
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 12%;
    grid-template-columns: auto auto auto auto;
}
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .flex_row_badge.flex_row_badge_moon {
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 12%;
    grid-template-columns: auto auto auto auto;
}
  #randomrocketx
  {
    top: 45px !important;
    left: 20px !important;
    color: #ffa60e;
    font-size: 40px !important;
  }
}

@media only screen and (min-width:1200px)
{
  .range_input_div.without_margin_div div.mt-custom-val
{
margin: 0px 0px 0px !important;
}
}

@media only screen and (min-width:1200px) and (max-width:1399px)
{
  #randomrocketx
  {
    top: 50px !important;
    left: 20px !important;
    color: #ffa60e;
    font-size: 40px !important;
  }
  .flex_row_badge.flex_row_badge_moon .badge
  {
    padding: 0 5px !important;
  }
}
@media only screen and (min-width:1400px)
{
#currentMultiplier {
left:25% !important;
}
}


.scene
{
    position: relative;
    width: 100%;
    height: 400px;
    /* background: #363636; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
    /* border:2px solid #ccc; */
    /* background-image: linear-gradient(100deg, #5d578f2b, #04040442); */
}


.scene .rocket
{
    position: relative;
    /* animation: animate 0.2s ease infinite; */
}


.scene i
{
    position: absolute;
    top: -250px;
    background: rgba(255,255,255,0.5);
    animation: animateStars linear infinite;
}

@keyframes animateStars {
    0%
    {
        transform: translateY(0);
    }
    100%
    {
        transform: translateY(200vh);
    }
}
@keyframes animate
{
    0%, 100%
    {
        transform: translateY(-2px);
    }
    50%
    {
        transform: translateY(2px);
    }
}

#moon_img
{
  position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1;
}
.rocket
{
  position: relative;
  z-index: 2;
}
#randomrocket
{
  position: absolute;
    top: 40px;
    left: 50%;
    right: 50%;
    color: #ffa60e;
    font-size: 16px;
    font-weight: 500;
    min-width: max-content;
    transform: translate(-50%,-50%);
}
#randomrocketx
{
  position: absolute;
  top:60px;
  left: 40px;
  color:var(--whtclr);
  font-size: 50px;
  font-weight: 500;
}
.active_rocket.bange_grey
{
background-color: #ffa60e;
color:#fff;
}
.active_rocket.bange_grey .bet_cirle
{
border-color: #fff;
}
#randomrocket.won_text_rocket
{
  color: #1fb51f !important;

}
#randomrocket.loss_text_rocket
{
  color: red !important;

}
/* end Moon */


/* Range game */
.range_values
{
  position: relative;
}
.range_val_div
{
  width: 24px;
  height: 24px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  color: #fff;
  font-size: 10px;
  background-color: #a2a2a2;
}
.range_val_div.green_div
{
background-color: #37dd4a;
}
.range_val_div.red_div
{
background-color: #d9113a;
}
.range_val_div p
{
  margin-bottom: 0px;
}
.range_input_div
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.range_input_div div
{
margin: 20px 10px;
}
.range_input_div.without_margin_div>div
{
margin: 0px 10px !important;
}


.range_input_div.without_margin_div>div div
{
margin: 0px 0px !important;
}


.range_input_div.without_margin_div div.mt-custom-val
{
margin: 20px 0px 0px !important;
}



.rangeslider__handle-label
{
  background-color: red !important;
  opacity: 1 !important;
  display: block !important;
}
.input_grp_win
{
  font-weight: 600;
    color: var(--whtclr);
    background-color: transparent;
    border: 2px solid #a2a2a2db;
    border-radius: 6px;
    height: 40px !important;
    margin: 0px !important;
}
.input_grp_win input
{
  border:none !important;
  height: 40px !important;
}
.input_grp_win div.input-group-append
{
  border:none !important;
  height: 40px !important;
  margin: 0px !important;
  display: flex;
    align-items: center;
    padding-right: 10px !important;
}

.range_slider_div .rangeslider__handle-label {
  background-color: transparent !important;
  opacity: 1 !important;
  display: block !important;
  top: 30px;
  position: absolute;
}
#randomnum
{
  font-size: 50px;
    text-align: center;
    color: #1fb51f;
}

@media only screen and (max-width:767px)
{
  .range_input_div
  {
    display: block !important;
    margin-top: 80px !important;
  }
  
}
@media only screen and (min-width:768px)

{
  .range_input_div div
  {
    min-width: 110px !important;
  }
  .range_input_div div.input_grp_win
  {
    max-width: 110px !important;
    flex-wrap: nowrap !important;

  }
  .range_input_div div.input_grp_win input
 
  {
    min-width: 85px !important;

  }
}
.text_div_range
{
  min-height: 50px;
}

.flex_row_badge_moon
{
  margin-top: 20px;
}

/* #win_loss_div,#randomnum
{
  min-height: 100px;
} */

 
/* end range game */

@media only screen and (min-width:1200px)
{
  .range_input_div.without_margin_div div.mt-custom-val
{
margin: 0px 0px 0px !important;
}
}

.bet_overlay_icon,.bet_overlay_icon:hover,.bet_overlay_icon:focus
{
  background-color: transparent;
  padding: 0px;
  border:none;
  margin-left: 10px;
}

@media only screen and (min-width:992px)
{
  .range_input_div div
{
  min-width: 170px !important;
}
}

.mt-cust-btn-range
{
  margin-top: 38px !important;
}


/* PLINKO? */
.bucket {
  /* width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #808285;
  margin: 3px 7px;
  position: relative;
  padding: 0px; */

  width: 26px;
    height: 26px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 100%;
    background: rgba(58, 77, 105, .16);
    padding: 1px;
}
.bucket::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #3a4d69;
}
/* .bucket_dot
{
  width: 10px;
  height: 10px;
  background-color: #36445e;
  border-radius: 50%;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
} */

.plinko-container
{
  /* max-width: 380px; */
  margin: 0 auto;
}

/* #ballpos
{
  margin: 0px 20px;
} */


/* / Plinko.css / */

.plinko-container {
  position: relative;
  width: max-content;
    padding: 20px;
  padding-top: 2%;
  overflow: hidden;
}

/* .ball {
  position: absolute;
  bottom: 0;
  width: 26px;
  height: 26px;
  background-color: #fcb740;
  box-shadow: inset -5px 0px 4px 1px #a27221;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: transform 1s ease-in-out;
  z-index: 1;
} */

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row_plinko {
  display: flex;
}
/* 
.bucket {
  width: 20px;
  height: 20px;
  margin: 2px;
} */


.boardbucket {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
}
.bucket_red
{
  background: #422137;
    border: 1px solid #422137;
    border-top: 4px solid #d9113a;
}
.bucket_yellow
{
  background: #422d21;
    border: 1px solid #422d21;
    border-top: 4px solid #ff7a00;
}
.bucket_orange
{
  background: #422129;
  border: 1px solid #422129;
  border-top: 4px solid #ff3d00;
}
.bucketbucket {
  /* width: 20px;
  height: 60px;
  margin: 2px;
  border-radius: 10px 10px 0px 0px;
  background-color: red; */

  width: 28px;
    /* height: 35px; */
    height: 28px;

  margin: 1px;

  margin-top: 20px;
    border-radius: 4px;
    transition: all .5s;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}


@keyframes fallAnimation {
  0% {
    top: 0;
  }
  100% {
    top: 90%;
  }
}


@keyframes zigzagFall {
  0% {
    top:0%;
    left:0%;
  }
  25% {
    top:20%;
    left:40%;
  }
  50% {
    top:40%;
    left:60%;
  }
  75% {
    top:60%;
    left:0%;
  }
  100% {
    top:80%;
    left:90%;
  }
}


@keyframes randomFall {
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(20%) translateY(20%);
  }
  50% {
    transform: translateX(40%) translateY(0);
  }
  75% {
    transform: translateX(60%) translateY(20%);
  }
  100% {
    transform: translateX(80%) translateY(90%);
  }
}

@media only screen and (max-width:575px)
{
.bucket {
    width: 12px !important;
    height: 12px !important;
    padding: 1px !important;
}
.bucket_dot {
  width: 5px !important;
  height: 5px !important;
} 
.bucketbucket {
  width: 12px !important;
  height: 20px !important;
  padding: 2px !important;
}
.bucket_text
{
  height: 12px !important;
  font-size: 4px !important;
}
.ball {
  
  width: 10px !important;
  height: 10px !important;
}
  
}
.bucket_text
{
  color:#fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 30px; */
  height: 23px;

}






/* venkatesh */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* hari */
.allareas {
  position: relative;
  font-family: monospace;
  color: rgb(255, 255, 255);
  font-size: 4em;
  filter: contrast(15);
}

.word {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: switch 7s infinite ease-in-out;
  min-width: 100%;
  margin: auto;
}

.word:nth-child(1) {
  animation-delay: -6s;
}

.word:nth-child(2) {
  animation-delay: -5s;
}

.word:nth-child(3) {
  animation-delay: -4s;
}
.rpss svg{
  margin-top: 8px;
}
@keyframes switch {

  0%,
  5%,
  100% {
      filter: blur(0px);
      opacity: 1;
  }

  50%,
  80% {
      filter: blur(180px);
      opacity: 0;
  }
}

.bg {
  /* background: #e3e3e3; */
  /* height: 100vh; */
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.board .row.row_with_bucket .hno {
  display: none;
}

.board .row.row_with_bucket:last-child .hno {
  display: block;
  color: #fff;
}

.bg button {
  margin-top: 2%;
}

.allboxs {
  margin-top: -3%;
  color: #fff;
  font-size: 14px;
}

.cell::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  /* background: #3a4d69; */
  /* background: #497fd0; */
  background: #fff;
}
.board .row.row_with_bucket:last-child .cell::after{
  display: none;
}
.cell{
  border-radius: 100%;
  background: rgba(58, 77, 105, .16);
  padding: 1px;
}

/* hari css */


.board {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.row {
  display: flex;
  justify-content: center;
}

.cell {
  width: 26px;
  height: 26px;
  border: 1px solid #cccccc54;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  /* Adjust for more space between squares */
}

.ball .ball_inner {
  width: 24px;
  height: 24px;
  background-color: var(--themebtnclr);
  border-radius: 50%;
  animation: bounce 0.5s infinite ease-in;
  position: absolute;
  z-index: 9;
  border: 2px solid var(--themebtnclr);
}

.bucket {
  width: 30px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  /* Adjust the slide distance as needed */
  100% {
    transform: translateX(0);
  }
}


.board .row_with_bucket:last-child .cell {
  /* height: 100px;
background-color: yellow; */
  /* position: absolute; */

  display: flex;
  width: 28px;
  height: 35px;
  background: #422137;
  border: 1px solid #422137;
  border-top: 4px solid #d9113a;
  border-radius: 4px;
  transition: all .5s;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  margin: 1px !important;
}

.board .row_with_bucket:last-child .cell.ball {
  /* height: 100px; */
  background-color: #422137;
  position: relative;
}
.board .row_with_bucket:last-child .cell.ball .ball_inner{
  /* animation: unset; */

}

/* END PLINKO */

/* #row_div .row_plinko:first-child .bucket:first-child
{
display: none;
} */



@media only screen and (min-width: 768px) and (max-width: 991px)  {
  .mobileplinko .plinko-container{
    padding: 0px;
  }
  .cell {
    width: 20px;
    height: 20px;
}
.board .row_with_bucket:last-child .cell {
  width: 22px;
  font-size: 10px;
}
.board .row_with_bucket:last-child {
  margin: 0;
}
.ball .ball_inner {
  width: 20px;
  height: 20px;
}
}


@media only screen and (max-width:767px)
{
  .mobileplinko .plinko-container{
    padding: 0px;
  }
  .board .row_with_bucket:last-child {
    margin: 0;
  }
  .board .row_with_bucket:last-child .cell {
    width: 28px;
  }
}

@media only screen and (max-width:575px)
{

  .cell {
    width: 12px;
    height: 12px;
    margin: 5px;
}
.cell::after {
  width: 4px;
  height: 4px;
}
.ball .ball_inner {
  width: 12px;
  height: 12px;
}

  .board .row_with_bucket:last-child .cell{
    width: 20px;
    font-size: 10px;
    height: 40px;
  }
  .board .row_with_bucket:last-child .cell.ball {
    width: 21px !important;
    height: 40px !important;
  }
  .board .row.row_with_bucket:last-child .hno {
    transform: rotate(90deg);
  }
}


@media only screen and (max-width:374px)
{

  .cell {
    width: 12px;
    height: 12px;
    margin: 4px;
}
.cell::after {
  width: 4px;
  height: 4px;
}
.ball .ball_inner {
  width: 12px;
  height: 12px;
}
.sidelogo {
  /* max-width: 56px; */
  margin-left: -4px !important;
  padding: 6px;
}
.sidebar {
  width: 54px;
}
.sidebar a {
  font-size: 11px;
  padding: 6px;
}
.sidebar a img {
  width: 22px;
  height: 22px;
  display: block;
}
.content {
  /* margin-left: 50px; */
}
.board .row_with_bucket:last-child {
  margin: 0;
}
.board .row_with_bucket:last-child .cell{
  width: 17px;
  font-size: 10px;
  height: 40px;
}
.board .row_with_bucket:last-child .cell.ball {
  width: 21px !important;
  height: 40px !important;
}
.board{
  overflow-x: auto;
  padding-bottom: 20px;
}
}