@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --backclr: #24262B;
  --bodybackclr: #1C1E22;
  --whtclr: #fff;
  --themebtnclr: #00B147;
  --themegrad:linear-gradient(90deg, #003F82 0%, #0072EC 100%);
  --themegrad1:linear-gradient(90deg,#0072EC  0%, #003F82   100%);

  --themebtnhover: #4084D5;
  --borderclr:#324A90;
  --borderbtnclr:#3E86D0;
  --black: #000;
  /* --sideactiveclr: #2F5DE3; */
  --sideactiveclr: #89BEFD;
  --inputclr:#4F75E4;
  --sidebarbg: #17191B
}
@font-face {
  font-family: 'Vendin';
  src: url('../assets/fonts/Vendin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.topcoin{
  position: absolute;
  width: 100px;
  right: 0;
  top: 15%;
  z-index: -1;
}
.botcoin{
  position: absolute;
  width: 100px;
  left: 0;
  bottom: 0%;
  z-index: -1;
}
.socials{
  position: fixed;
  right: 2%;
  transform: rotate(90deg);
  transform-origin: right;
  top: 63%;
  z-index: 777;
}
.socials a img{
  transform: rotate(270deg);
  width: 30px;
  height: 30px;
}
.blueice{
  width: 68%;
  margin: auto;
}
.f-12{
  font-size: 14px;
  font-weight: 200;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  background: #101118 !important;
  color: #fff !important;
  border: 1px solid #80808021 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color: var(--themebtnclr) !important;
}

div:where(.swal2-icon){
  border-color: transparent !important;
}
.backred{
  color: red !important;
  /* background: #000000a8;
  padding: 2px 10px;
  border-radius: 2px; */
  font-size: 14px;
}
video::-webkit-media-controls {
  display:none !important;
}
video::-webkit-media-controls-panel {
display: none!important;
-webkit-appearance: none;
}
video::-webkit-media-controls-play-button {
display: none!important;
-webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
display: none!important;
-webkit-appearance: none;
}
.pos{
  position: relative;
  z-index: 4;
  overflow: hidden;
}
.diceimg1{
  position: absolute;
  left: 15%;
  top: 26%;
  opacity: 0.8;
  z-index: -1;
  transform: translatex(0);
  animation: 6s ease-in-out infinite floatx;
}
.diceimg2{
  position: absolute;
  right: 20%;
  bottom: 30%;
  opacity: 0.8;
  z-index: -1;
  transform: translatex(0);
  animation: 6s ease-in-out infinite floatx;
}
.diceimg3{
  position: absolute;
  right: 25%;
  opacity: 0.8;
  transform: translatex(0);
  animation: 6s ease-in-out infinite floatx;
}
.diceimg4{
  position: absolute;
  left: 28%;
  bottom: 10%;
  opacity: 0.7;
  transform: translatex(0);
  animation: 6s ease-in-out infinite floatx;
}
.squaress{
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  overflow: hidden !important;
  bottom: -75px;
  z-index: -3;
  animation: animatess 25s linear infinite;
}
.squaress.rangeside{
  background-color: #ff3cac;
  background-image: linear-gradient(225deg, #ff3cac 0%, #784ba0 50%, #2b86c5 100%);
}
.squaress.wheelside{
  background-color: #ff3cac;
  background-image: linear-gradient(225deg, #2f2c53 0%, #784ba0 50%, #2b2253 100%);
}
.squaress.moonslide {
  background-color: #ff3cac;
  background-image: linear-gradient(225deg, #2f2c53 0%, #497fd0 50%, #2b2253 100%);
}
.square1{
  width: 60px;
  height: 60px;
  animation-delay: 0s !important;
  animation-duration: 18s !important;
  left: 30%;
}
.square2{
  right: 28%;
  width: 25px;
  height: 25px;
  animation-delay: 15s !important;
  animation-duration: 15s !important;
}
.square3{
  left: 75%;
  width: 75px;
  height: 75px;
  animation-delay: 3s !important;
  animation-duration: 25s !important;
}
.square4{
  left: 50%;
  width: 40px;
  height: 40px;
  animation-delay: 5s !important;
  animation-duration: 15s !important;
}
.square5{
  left: 20%;
  width: 40px;
  height: 40px;
  animation-delay: 2s !important;
  animation-duration: 17s !important;
}
.square6{
  left: 5%;
  width: 30px;
  height: 30px;
  animation-delay: 4s !important;
  animation-duration: 19s !important;
}
.square7{
  right: 5%;
  width: 30px;
  height: 30px;
  animation-delay: 4s !important;
  animation-duration: 19s !important;
}
.square8{
  right: 15%;
  width: 36px;
  height: 36px;
  animation-delay: 3s !important;
  animation-duration: 14s !important;
}
.square8{
  left: 19%;
  width: 39px;
  height: 39px;
  animation-delay: 5s !important;
  animation-duration: 17s !important;
}
@keyframes animatess{
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.6;
    border-radius: 0;
}
100% {
    transform: translateY(-600px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
}
}
@keyframes floatx {
  0%, 100% {
    transform: translatex(0);
}

50% {
    transform: translatex(-15px);
}
}
@keyframes wiggle{
  0%, 100%, 40%, 7% {
    transform: rotateZ(0);
}
15% {
    transform: rotateZ(-15deg);
}
20% {
    transform: rotateZ(10deg);
}
25% {
    transform: rotateZ(-5deg);
}
30% {
    transform: rotateZ(3deg);
}
35% {
    transform: rotateZ(-1deg);
}
}


/* .bg_dice::after{
  content: "";
  background: url("../assets/bg_dice.gif") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
    z-index: -5;
    opacity: 0.4;
} */
 .grayclrs{
  color: gray;
 }
 .payment_det{
  display: flex;
  gap: 10px 24px;
  flex-wrap: wrap;
  margin-top: 8px;
 }
 .offpay .form-check{
  display: flex;
  align-items: center;
  gap: 6px 10px;
 }
 .stakeborder.stages{
  height: unset !important;
  min-height: unset !important;
  max-height: unset !important;
 }
 .swap_borderss{
  display: flex;
  padding-left: 0px;
  gap: 10px 20px;
 }
 .swap_borderss li{
  list-style-type: none;
  cursor: pointer;
  color: #000;
  text-transform: uppercase;
  font-size: 21px;
  padding: 10px;
  font-weight: 600;
  border-bottom: 3px solid transparent;
 }
 .swap_borderss li:hover,  .swap_borderss li.active{
  border-bottom: 3px solid black;
 }

 .stars{
  color: #4F75E4;
  font-size: 29px;
  margin-top: -3px;
 }
 .dots{
  background-color: #4F75E4;
  width: 18px;
  height: 18px;
  border-radius: 50px;
 }
 .max_100{
  max-width: 100% !important;
 }
 .backsss{
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -5;
  opacity: 0.2;
 }
.swap_title{
  color: #fff;
  background: #3896B4;
  border: 2px solid #62C9EE;
  width: 150px;
  min-width: 150px;
  border-radius: 50px;
  padding: 10px;
  text-align: center;
  margin: 5px auto 25px auto;
}
.swap_border{
  border: 1px solid #62C9EE;
  border-radius: 50px;
  display: flex;
  max-width: fit-content;
  margin: auto;
  padding-left: 0px;
}
.swap_border li{
 color: #fff;
 list-style-type: none;
 font-size: 19px;
 padding: 16px 26px;
 border-radius: 50px;
 border: 3px solid transparent;
 transition: 0.5s;
 cursor: pointer;
}
.swap_border li:hover, .swap_border li.active{
  background-color: #3896B4;
  border: 3px solid #62C9EE;
  transition: 0.5s;
}
.ftext{
  /* color: #3EF602; */
  color: #8AC0FF;
  font-weight: 500;
  font-family: 'Vendin';
}
.m_minus{
  margin-top: -15px;
}
.sectext{
  /* color: #3B87D5; */
  color: #ABE1FF;
}
.rightcoin{
  position: absolute;
  right: 0;
  top: 22%;
  z-index: -1;
}
.leftcoin{
  position: absolute;
  left: 12%;
  bottom: 14%;
  z-index: -1;
}
.footer{
  background: url("../assets/footerback.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.footer .footerhead{
  color: #5eabf9 !important;
}
.position{
  position: relative;
  z-index: 4;
}
.flip{
  transform: translatey(0);
  animation: 6s ease-in-out infinite float
}
@keyframes float{
  0%, 100% {
    transform: translatey(0);
}

50% {
    transform: translatey(-15px);
}
}
.flip1{
  position: absolute;
  width: 6%;
  left: 20%;
}
.flip2{
  position: absolute;
  width: 7%;
  right: 20%;
}
.flip3{
  position: absolute;
  width: 5%;
  top: 50%;
  left: 28%;
}
.flip4{
  position: absolute;
  width: 5%;
  top: 50%;
  right: 25%;
}
.poppins{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.banner_listed{
  background: url("../assets/sidemenu/bannernew1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 200px;
  /* min-height: 300px; */
  position: relative;
}
.banner_listed .bannertext{
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%,-50%);
  animation: animbannertext 5s linear infinite;
}
.th_clr{
  background: linear-gradient(180deg, #3E86D0 0%, #4E73DE 100%) !important;
}
.accordion-item,.accordion-button{
  background-color: transparent !important;
  color: var(--whtclr) !important;
  border-color: transparent !important;
  box-shadow: unset !important;
  font-weight: 700;
}
.accordion-button{
  padding-left: 10px;
}
.accordion-button::after{
  filter: invert(1);
}
.accordion-button:not(.collapsed)::after{
  filter:  brightness(0) invert(1) !important;
}
.content_right{
  padding-left: 15.5vw;
 
}
.innerright{
  min-height: 80vh;
}
.leftsidebar{
  background-color:  var(--sidebarbg);
  width: 15vw;
  position: fixed;
  left: 0;
  top: 90px;
  z-index: 7777;
  height: 100vh;
  overflow-y: auto;
  transition: 0.5s;
  padding: 15px;
}
.gameheader_sidebar .leftsidebar{
  width: unset;
  position: unset;
  height: unset;
  overflow: unset;
}
.leftsidebar.widthset{
  width: 20px;
  transition: 0.5s;
}
.container_max{
  max-width: 100%;
}
.form-control{
  background-color: transparent !important;
  border: 1px solid #454545 !important;
  color: var(--whtclr) !important;
}
.form-control::placeholder{
  color: var(--whtclr) !important;
}
.number_border{
  background-color: var(--backclr);
  border-radius: 5px;
  height: 100%;
}
.number_border label{
  font-size: 15px;
  font-weight: 500 !important;
}
.btn-warning.disabled, .btn-warning:disabled {
  border-color: var(--themebtnclr);
}
.card{
  background-color: transparent !important;
}
.dropdown-menu{
   background-color: var(--backclr) !important;
   border: 1px solid #8080803b;
   box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
.whtclr,p{
  color: var(--whtclr) !important;
}
.searchicon{
  height: 38px;
  background: var(--inputclr);
  border-color: var(--inputclr);
  color: var(--whtclr);
}
.menuheight .dropdown-item{
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 8px 12px;
}
.menuheight .dropdown-item:hover{
  background-color: var(--themebtnclr);
  color: #ffffff;
}
.etimg{
  width: 21px;
  height: 21px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.comingsoon{
  height: 40vh;
}

@media screen and (max-width:575px) {
  .diceall{
    width: 10%;
  }
  .squaress{
    width: 25px !important;
    height: 25px !important;
  }
  .comingsoon{
    height: 18vh;
  }
}
@media screen and (max-width:374px) {
  .comingsoon h1{
   font-size: 19px;
  }
}





.sidebarlist{
  overflow: hidden;
}
body {
  background-color: var(--bodybackclr);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
body::-webkit-scrollbar-track, .leftsidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar, .leftsidebar::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb, .leftsidebar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--borderbtnclr);
}

.apexcharts-legend{
  display: none !important;
}
.apexcharts-datalabels{
  display: none !important;
}

.f-13{
  font-size: 13px;
}
.step1 img{
  border-radius: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.fw-900 {
  font-weight: 900;
}
.fw-800 {
  font-weight: 800;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}

.header_alert__8Mnqu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 0 20px;
  background: #24272d;
  color: black;
  /* height: 35px; */
  /* height: 70px; */

  font-size: 15px;
  opacity: 1;
  transition: .3s ease-in-out;
  /* position: relative;
    width: 6200px;
    left: calc(50% - 3100px); */
}
.gameheader .header_alert__8Mnqu{
  display: grid;
  align-items: center;
  justify-content: unset;
  padding: 10px 30px;
}
.cw{
  color: var(--themebtnclr);
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  transition: 0.5s;
}
.cw:hover{
  color: black;
  transition: 0.5s;
}
.gameheader .wimg{
  /* filter: brightness(0.5); */
}
.gameheader .headlogo{
  height: 70px;
  width: 85px;
}
.gameheader .navbar a,.navbar a{
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  
}
.navbar a.active{
  color: #70b6fb;
}
.gameheader .navbar a:hover{
  color: #70b6fb;
}
.sidebarlist .header_alert__8Mnqu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 77;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.header_alert__text__uomPs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_alert__8Mnqu .header_winr__Lq3w1 {
  color: black;
  margin-right: 4px;
}

.header_alert__8Mnqu .header_address__pkb9E {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: .3s;
  cursor: pointer;
}
.me-auto{
  padding-left: 40px;
}
.wallet {
  background-color: var(--themebtnclr) !important;
  /* border-color: var(--themebtnclr) !important; */
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px !important;
  border: 2px solid var(--themebtnclr);
}
.wallets {
  background-color: #606CDA !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px !important;
  border: 2px solid #606CDA;
}
.wallet:hover {
  background-color: var(--themebtnhover) !important;
  border-color: var(--themebtnhover) !important;
  color: #fff !important;
}

.play {
  max-width: 140px;
  width: 100%;
  min-width: 140px;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
  font-weight: 600;
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
}
.referral{
  color: var(--themebtnclr);
}
.referralticket{
  /* background: url("../assets/referral-box.svg.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 26px 20px;
  border-radius: 10px;
}
.referralticket1{
  background-color: #4F75E4;
}
.referralticket2{
  background-color: #9072F1;
}
.referralticket3{
  background-color: #4F75E4;
}
.referralticket img{
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  object-fit: contain;}
/* home */
.leftside {
  position: relative;
  z-index: 5;
  overflow: hidden;
}
.msgfun{
  position: fixed;
  right: 0;
  z-index: 777;
  bottom: 20%;
}
.referborder{
  border-radius: 8px !important;
  padding: 20px 18px !important;
  background: unset !important;
  background-color: #1d202e !important;

}
.inner_content{
  min-height: 60vh;
}
.heightborder{
  height: 300px;
  max-height: 300px;
  min-height: 300px;
}
.modalborder{
  border: none !important;
  padding: unset !important;
  background: unset !important;
}
.btn-outline-warning{
  border: 2px solid var(--themebtnclr) !important;
  color: black !important;
  font-size: 13px;
  border-radius: 6PX !important;
  font-weight: 500;
}
.btn-outline-warning:hover{
  color: #fff !important;
  background-color: var(--themebtnclr) !important;
}
.groupicon {
  position: absolute;
  top: 68%;
  max-width: 70%;
  left: 20%;
}

.allcoin {
  margin-top: -25%;
}

.allhome {
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.css-d7l1ni-option {
  background-color: #ffa60e !important;
  color: white !important;
  border-radius: 8px;
}
.css-tr4s17-option {
  background-color: #ffa60e !important;
  border-radius: 8px;
}
.css-d7l1ni-option::after{
 content: "";
 background: url("../assets/arrow.png");
}
.social a, .socials a {
  margin-left: 12px;
}
.css-13cymwt-control {
  border-color: hsl(0deg 0% 95.69%) !important;
}
/* .footer .social a img {
  filter: brightness(0.5);
} */

.social a:hover, .socials a:hover {
  filter: drop-shadow(2px 4px 6px var(--themebtnhover));
}

.bottomcoin {
  position: fixed;
  width: 100%;
  left: 9%;
  margin: auto;
  max-width: 82%;
  min-width: 82%;
  bottom: -4%;
  z-index: 7;
}
.offcanvas {
  background-color: #121217 !important;
}
.navbar-toggler {
  background-color: #ffffff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.walletlist {
  border: 1px solid #cfcfcf26;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transform: 0.5s;
}
.modal-header{
  border-bottom: 1px solid #cfcfcf26 ;
}
.modal-title {
  font-family: "Vendin";
  font-weight: 400;
}
.walletlist:hover {
  background: var(--themegrad);
  border: 1px solid transparent;
  color: #fff;
}

.walletlist img {
  width: 40px;
  height: 40px;
}

.sidebarlist .header2 {
  display: none;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 80px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  /* overflow: auto; */
  /* overflow-y: auto; */
  z-index: 999;
  top: 7% !important;
}

.sidebar span {
  margin-top: 6px;
}

.sidebar a .hoverimg {
  display: none;
  text-align: center;
  /* margin-left: 12px; */
}
.bbs{
  border-bottom: 1px solid gray;
}
.leftsidebar a .hoverimg{
 display: none;
} 
.sidebar a:hover .hoverimg {
  display: block;
}
.leftsidebar a:hover .hoverimg, .leftsidebar a.active .hoverimg {
  display: inline-block;
}
.sidebar a:hover .normalimg, .leftsidebar a:hover .normalimg {
  display: none;
}

.sidebar a.active .normalimg, .leftsidebar a.active .normalimg {
  display: none;
}

.sidebar a.active .hoverimg {
  display: block;
}

.sidebar a img, .leftsidebar a img {
  width: 29px;
  height: 29px;
  object-fit: contain;
  margin: auto;
}
.sidebar .logos img , .leftsidebar .logos img{
  width: unset;
  height: unset;
  object-fit: unset;
  margin: auto;
  /* filter: drop-shadow(0px 1px 4px gray); */
}
.sidebar a.active, .leftsidebar a.active {
  color: var(--sideactiveclr);
  /* border-right: 2px solid var(--themebtnclr); */
}

.sidebar a, .leftsidebar a {
  display: block;
  color: var(--whtclr);
  background: var(--backclr);
  border-radius: 7px;
  font-weight: 6  00;
  padding: 10px;
  /* text-align: center; */
  text-align: start;
  text-decoration: none;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  /* border-right: 2px solid #f4f4f4; */
}
.sidebar a, .leftsidebar a:hover{
  background: var(--themegrad);
}
.sidebar .logos{
  padding: 4px !important;
}
.sidebar .dropdown-content a {
  margin-top: 0px;
  border: none;
  text-align: left !important;
}

.sidebar .dropdown a:hover {
  border: none;
}

.sidebar a:hover {
  color: var(--themebtnclr);
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border-right: 2px solid var(--themebtnclr);
  /* filter: drop-shadow(2px 4px 10px #ffa60e); */
}

.leftsidebar .a_img a:hover .normalimg{
  display: none;
}
/* .leftsidebar .a_img a .hoverimg{
  display: none;
} */
/* .leftsidebar .a_img a:hover .hoverimg{
  display: block;
}
.leftsidebar .a_img a .hoverimg{
  display: none;
} */

.leftsidebar a:hover{
  color: var(--white);
}
.leftsidebar a.active{
  color: var(--white);
  background: var(--themegrad);
}
.sidebar .logos:hover{
  border: none !important;
}
.sidebar .active {
  color: var(--themebtnclr);
}
.leftsidebar .a_img a span{
  color: var(--whtclr);
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}
.leftsidebar .a_img a {
  padding:10px !important
}
.leftsidebar .a_img img{
  width: 25px;
  height: 25px;
}

.content {
  /* margin-left: 80px; */
}

.sidelogo {
  padding: 10px;
  max-width: 80px;
  margin: auto;
}


.headingz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-success {
  color: #fff;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 9%);
  border-radius: 8px !important;
}
.walletdrop .dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}
.walletdrop .ptoken {
  /* color: black !important; */
    font-weight: 500;
}
.walletdrop .wimg {
  filter: unset;
}
.copysvg{
  color: var(--themebtnclr);
}
.walletdrop a{ 
  /* color: black; */
  color: var(--whtclr);
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 12px;
  display: block;
  transition: 0.5s;
}
.form-check-input:checked {
  background-color: var(--themebtnclr);
  border-color: var(--themebtnclr);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 166 14 / 24%) !important;
}
.walletdrop a:hover{
  color: var(--themebtnclr);
  transition: 0.5s;
}
.contest {
  background-color: #036837 !important;
  border-color: #036837 !important;
}

.stakebtn {
  background-color: #625AEA !important;
  border-color: #625AEA !important;
}

.earnbtn {
  background: var(--themegrad) !important;
  /* border-color: var(--themebtnclr) !important; */
  border: 2px solid transparent;
}
.earnbtn:hover{
  background: transparent;

}
.earnbtn.bluebtn{
  /* background-color: var(--black) !important;
  border: 2px solid var(--black) !important; */
  background:var(--themegrad) !important;
  border: 2px solid transparent !important;
  padding: 10px;
}
.earnbtn.bluebtn:hover, .earnbtn.bluebtn:focus{
  background: var(--themegrad1) !important;
  border: 2px solid transparent !important;
}
.btn-success:hover ,.btn-success:focus{
  background-color: var(--themebtnhover) !important;
  border-color: var(--themebtnhover) !important;
}

.dashboard {
  /* border: 1px solid #e3e3e3;
  background-color: #e3e3e3; */
  /* background: url("../assets/dashbackimg1.png") no-repeat !important; */
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  padding: 17px 9px 17px 9px;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 2%);
  /* border: 2px solid #d9d9d9; */
  position: relative;
  overflow: hidden;
  z-index: 6;
  background: #4F75E4;
  border: 2px solid #7B9BF8;
}
.dashboardimg1{
  /* background: url("../assets/dashbackimg2.png") no-repeat !important;
  background-size: 100% 100% !important; */
  background: #734FE4;
  border: 2px solid #9072F1
}
.dashboardimg2{
  /* background: url("../assets/dashbackimg3.png") no-repeat !important;
  background-size: 100% 100% !important; */
  background: #4095B2;
  border: 2px solid #6ECAEA;
}
.dashboardimg3{
  /* background: url("../assets/dashbackimg3.png") no-repeat !important;
  background-size: 100% 100% !important; */
  background: #5075DF;
  border: 2px solid #7B9BF8;
}
.tri{
  position: absolute;
  top: 50%;
  left: -2%;
  max-width: 30px;
  z-index: -1;
}

.dashboard:hover {
  /* box-shadow: 0 3px 10px rgb(255 166 14 / 42%); */
}

.dashboard p {
  font-size: 14px;
  margin-bottom: 0px !important;
  margin-top: 8px;
}
/* .dashboard p:nth-child(1) {
  color: #474d59;
} */

.dropdown {
  position: relative;
  /* display: inline-block; */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  z-index: 1;
  left: 81px;
  top: 0;
  border-radius: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropsideimg {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 8px !important;
  background-color :#17191B;
  padding: 5px;
  border-radius: 50%;
}

.owlcarousel .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--themebtnhover);
}

.owlcarousel .owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 8px;
}

/* .owlcarousel .item {
  background: url("../assets/backimg.png");
  background-repeat: no-repeat;
  padding: 15px 20px;
  border-radius: 8px;
  background-color: #e3e3e3;
  background-position: right;
 
} */

.btn-outline-secondary {
  color: #363636 !important;
  border-color: #5b606a !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 14px;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #5b606a !important;
  border-color: #5b606a !important;
}

.clr {
  color: #95989d;
}

.allbtns {
  text-align: end;
}

.btn-light {
  color: var(--whtclr) !important;
  background-color: transparent !important;
  border-color: var(--borderbtnclr) !important;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.howtoplay .btn-light{
  border: 1px solid #1715187a !important;
}
.btn-light:hover {
  color: #fff !important;
  background-color: var(--borderbtnclr) !important;
  border-color: var(--borderbtnclr) !important;
}

.cardss .card {
  border-radius: 6px;
  transition: all .3s ease;
}

.cardss a {
  text-decoration: none;
}

.cardss .card:hover {
  /* transform: translateY(-5px); */
}

.cardss .card-img {
  position: relative;
  overflow: hidden;
}

hr {
  border-color: black;
  opacity: 0.8 !important;
}

hr:not([size]) {
  height: 2px !important;
}

.cardss .gameimg {
  border-radius: 6px 6px 0px 0px;
  /* height: 200px;
  max-height: 200px;
  min-height: 200px; */
  height: 210px;
  max-height: 210px;
  min-height: 210px;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease;
  /* filter: hue-rotate(45deg); */
}

/* .cardss img:hover {
  transform: scale(1.1);
} */
.volumeimg{
  margin-top: -2px;
}
.cardss .card-body {
  padding: 10px 10px;
  /* background-color: #363636; */
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  margin-top: -1px;
}

.footer ul {
  padding-left: 0px !important;
}

.footer li {
  list-style-type: none;
  margin-top: 8px;
}

.footer li a {
  color: var(--whtclr);
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.footer li a:hover {
  color: var(--themebtnhover);
}

.foot-p {
  font-size: 14px;
}
.btn-close{
  color: var(--whtclr) !important;
  filter: invert(1);
}
.modal-content {
  background-color: var(--backclr) !important;
  border-radius: 8px;
  box-shadow: 0 3px 10px #ffffff1a;
  border:1px solid #80808036;
}
.gameborder {
  /* border: 1px solid #e3e3e3; */
  /* background-color: #e3e3e3; */
  padding: 0px 14px 0px 14px;
  border-radius: 10px 10px 10px 10px;
  background: url("../assets/gameback.png") no-repeat;
  background-size: contain;
  width: 100%;
  background-color: #1d202e;
  height: 100%;
  background-position: bottom;
}
.gameborder.dicebackimg{
  background: url("../assets/dicebackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
}
.gameborder.rangebackimg{
  background: url("../assets/rangebackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
}
.gameborder.plinkobackimg{
  background: url("../assets/plinkobackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
}
.gameborder.coinflipbackimg{
  background: url("../assets/coinflipbackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
  position: relative;
  z-index: 4;
}
.gameborder.wheelbackimg{
  background: #1c212fcf url("../assets/wheelbackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
  position: relative;
  z-index: 4;
}
.gameborder.moonbackimg{
  background: #1c212fcf url("../assets/moonbackimg.png") no-repeat !important;
  background-color: #1c212fcf !important;
  background-size: 100% 100% !important;
  position: relative;
  z-index: 4;
}
.gameborder.limbobackimg{
  background: url("../assets/limbobackimg.png") no-repeat !important;
  background-color: #1d202e !important;
  background-size: 100% 100% !important;
}
.gameborder.rpsbackimg{
  background: url("../assets/rpsbackimg.png") no-repeat !important;
  background-color: #1d202e !important;
  background-size: 100% 100% !important;
}
.gameborder.coinflipbackimg::after, .gameborder.wheelbackimg::after,.gameborder.moonbackimg::after{
  content: "";
  position: absolute;
  left: 0;
  background-color: #1c212fcf;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 8px;
}
.gameborder.moonbackimg::after{
  content: "";
  background-color: #1c212fed;
}
.play{
  cursor: pointer;
  transition: 0.5s;
}
.play:hover{
  color: var(--themebtnclr);
  transition: 0.5s;
}
.table>thead {
  /* border-bottom: 2px solid #e3e3e3 !important; */
  /* background-color: #e3e3e3; */
  background: linear-gradient(90deg, #003F82 0%, #0072EC 100%);
  font-size: 15px;
}
.table>thead  th{
  color: var(--whtclr);
  font-size: 16px;
}
.table>:not(caption)>*>* {
  padding: 0.8rem 0.5rem !important;
}

tbody tr td:last-child {
  color: var(--themebtnclr);
}

tbody {
  font-size: 15px;
  font-weight: 600;
}

.table tbody tr {
  border-bottom: 1px solid var(--borderclr);
}
.table tbody tr:hover div{
  color: #fff;
}
tbody img {
  margin-top: -4px;
}
.timess{
  filter: invert(1);
}
.alltable {
  overflow: auto;
}

.alltable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.alltable::-webkit-scrollbar {
  width: 4px;
  height: 7px;
  background-color: #F5F5F5;
}

.alltable::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 4px;
}


.backsideimg {
  background: url("../assets/coinback.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.gameborder .form-control {
  font-weight: 600;
  color: black;
  background-color: transparent;
  border: 2px solid #a2a2a2db;
  border-radius: 6px;

}
.input-group .form-control{
  /* border-right-color: transparent; */
}
.gameborder .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -18px;
}
.gameborder .input-group-text {
    background-color: transparent;
    border: 2px solid #ababab;
    border-radius: 0.25rem;
    border-left-color: transparent;
    position: relative;
}
.nochange{
  position: absolute;
  right: 55px;
  top: 8px;
}
.nochange1{
  position: absolute;
  left: 14px !important;
  top: 8px;
  font-weight: 600;
}
.uparrow{
  position: absolute;
  top: 2px;
  left: -4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.downarrow{
  position: absolute;
  bottom: 2px;
  left: -4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.range1 .rangeslider {
  position: absolute;
  bottom: 2px;
    z-index: 7;
    width: 100%;
}
.btn-dark {
  color: #fff;
  background-color: #344158 !important;
  border-color: #344158 !important;
  font-size: 12px !important;
  padding: 2px 10px !important;
}
.btn-dark:hover{
  background-color: black !important;
  border-color: black !important;
}
.rangeslider {
  background: #353535 !important;
  margin: -2px 0 !important;
}
.rangeslider-horizontal {
  height: 7px !important;
  border-radius: 10px !important;
}
.rangebackimg  .rangeslider-horizontal {
  height: 13px !important;
}
.rangebackimg .rangeslider-horizontal .rangeslider__handle {
  width: 28px !important;
  height: 28px !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 6px !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  width: 12px !important;
  height: 12px !important;
  top: 4px !important;
  left: 3px !important;
  background-color: #ffffffa3 !important;
}
.rangebackimg .rangeslider-horizontal .rangeslider__handle:after {
  width: 16px !important;
  height: 16px !important;
  border-radius: 14% !important;
  top: 5px !important;
  left: 5px !important;
  background-color: #ffffff !important;
}
.rangeslider .rangeslider__handle {
  background: var(--inputclr) !important;
  border: 1px solid var(--inputclr) !important;
  box-shadow: unset !important;
}
.number_border .rangeslider .rangeslider__handle {
  background:transparent!important;
  border: 1px solid var(--inputclr) !important;
  border-radius: 25px;
}
.rangebackimg .rangeslider .rangeslider__handle {
  background: var(--inputclr) !important;
  border: 1px solid var(--inputclr) !important;
  box-shadow: unset !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background: var(--themegrad) !important;
}
.rangebackimg .range_slider_div .rangeslider__handle-label{
  color: var(--whtclr);
}
.borders{
  border: 1px solid var(--sidebarbg);
    background-color: var(--sidebarbg);
    border-radius: 6px;
    padding: 4px 8px;
    color: var(--whtclr);
}
.que{
  /* color: black; */
  color: #fff;
  font-size: 15px;
  margin-top: -3px;
}
summary {
  display: block;
  position: relative;
}
/* summary::before{
  content: "";
  border: 1px solid var(--whtclr);
  position: absolute;
  top: 12px;
  width: 100%;
  max-width: 40%;
  right: 0%;
} */
/* the rest is just for esthetics */

summary::after {
  margin-left: 1ch;
  display: inline-block;
  content: '▶️';
  transition: 0.2s;
    transform: rotate(360deg);
   
}
details[open] summary::after {
  transform: rotate(90deg);
}
.walletss{
  font-size: 15px !important;
  font-weight: 600 !important;
}
.dayss{
  color: #4c4c4c !important;
}
.bottomborder{
  /* background-color: rgb(161 161 161);
  border: 1px solid rgb(161 161 161); */
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}
.dblack{
  color: black;
  font-weight: 700;
}
.lgray{
  color: #8b8b8b;
  font-weight: 700;
  font-size: 13px;
}
.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 166 14 / 12%) !important;
}
.step1 .firstbtn{
  background-color: black !important;
}
.step1 .firstbtn svg{
  color: #fff;
}
.css-1dimb5e-singleValue {
  font-size: 13px;
  font-weight: 700;
}
.css-1jqq78o-placeholder,.css-10wo9uf-option {
font-size: 13px;
}
/* .css-d7l1ni-option {
  font-size: 13px !important;
} */
.css-1nmdiq5-menu {
  max-width: 150px;
  min-width: 150px;
  border-radius: 8px !important;
}
.css-1n6sfyn-MenuList {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.walletdrop .dropdown-menu{
  padding: 0.8rem 0.8rem;
  min-width: 14rem;
  border-radius: 10px;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 0%) !important;
}
.tokenborder{
  border: 1px solid #858383;
  padding: 6px;
  border-radius: 6px;
}
.stakeborder{
  padding: 20px !important;
  border-radius: 10px !important;
  background: linear-gradient(180deg, #3E86D0 0%, #4E73DE 100%);
}
.stakeborder .nochange1 {
  left: 30px !important;
}
[aria-label="tail-spin-loading"]{
  justify-content: center;
}
.stakeborder .btn-outline-secondary{
  border: 2px solid #a2a2a2db !important;
  text-transform: unset !important;
  font-size: 17px !important;
}
.stakeborder .rangeslider .rangeslider__handle {
  background: var(--themebtnclr) !important;
  border: 1px solid var(--themebtnclr) !important;
}
.stakeborder .rangeslider-horizontal .rangeslider__handle {
  width: 7px !important;
}
.stakeborder .rangeslider-horizontal .rangeslider__handle:after {
 display: none !important;
}
.form-control:focus {
  box-shadow: unset;
  background-color: transparent !important;
}
.rewardimg{
  position: relative;
  /* overflow: hidden; */
}
.coingold{
  position: absolute;
    left: 2px;
    top: -6px;
    width: 100%;
}
.bottomborder .rangeslider {
  background: #e3e3e3 !important;
}
.bottomborder .rangeslider-horizontal {
  height: 5px !important;
}
.bottomborder .rangeslider .rangeslider__handle {
  background: #e3e3e3 !important;
}
.nthno {
  /* color: rgb(104 117 140);
  font-weight: 700; */
  color: #363636;
  font-weight: 600;
}
.stakeborder .borders{
    border-radius: 12px;
}
.stakeborder{
  height: 460px;
  min-height: 460px;
  max-height: 460px;
  position: relative;
  z-index: 6;
}
.wlpdrop{
  background-color: #c8c8c8;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 15px;
  font-weight: 600;
}
.piecharts .dropdown-menu {
  background-color: #f4f4f4;
  border: 1px solid rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 10px;
}
.piecharts ul {
  padding-left: 0px !important;
  list-style-type: none;
  margin-top: 6%;
}
.piecharts ul li {
 display: flex;
 justify-content: space-between;
 border-bottom: 1px solid black;
}
.rewardbg{
  background: url("../assets/rewardsbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 14px 16px;
}
.wlpb{
  background-color: white;
  border: 1px solid #ffffff;
  padding: 4px 10px;
  border-radius: 10px;
}
.css-tj5bde-Svg {
  fill: #5b5a5a !important;
}
.liness{
  /* max-width: 100% !important;
  width: unset !important; */
  max-width: 42% !important;
  width: 100% !important;
  margin-top: -14px;
}
.fees{
  color: rgb(104 117 140);
}
.stakeborder .nav-pills{
  background: #c8c8c8;
  border-radius: 20px;
  width: fit-content;
}
.earntab{
  /* background-color: rgb(200 200 200); */
  padding: 14px 18px;
}
.earntab .css-13cymwt-control {
  border-radius: 10px !important;
}
.earntab .form-control{
    background-color: var(--inputclr) !important;
    border: 1px solid var(--inputclr) !important;
    height: 34px;
    max-width: 50%;
    text-align: end;
    border-radius: 10px;
}
.earntab .swapbtn .form-control{
  background-color: unset;
    border: unset;
    height: unset;
    max-width: unset;
    text-align: unset;
    border-radius: unset;
}
.swapbtn .input-group{
  border: 1px solid gray;
    border-radius: 6px;
}
.swapbtn .input-group-text{
  border: 2px solid transparent;
}

.stakeborder .nav-pills .nav-link {
  font-size: 15px;
  color: black !important;
  font-weight: 600;
  padding: 10px 20px;
}
.stakeborder .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #ffff !important;
  background-color: var(--themebtnclr);
  border-radius: 20px;
}
.piecharts ul p:nth-child(1){
  color: #68758c !important;
  font-weight: 700;
  font-size: 15px;
}
.piecharts ul p:nth-child(2){
  font-weight: 600;
  font-size: 15px;
}
.piecharts .dropdown-item{
  font-size: 13px;
  font-weight: 600;
}
.piecharts .dropdown-item:hover {
  color: #fff;
  background-color: var(--themebtnclr);
  border-radius: 6px;
}
.btn:focus {
  box-shadow: unset !important;
}
.walletsss{
  font-weight: 600 !important;
  font-size: 16px !important;
}
/* .stakeborder::after{
content: "";
background: url("../assets/vinmeen.png");
background-repeat: no-repeat;
position: absolute;
top: 0;
} */
/* .createdborder{
  height: unset !important;
  min-height: unset !important;
  max-height: unset !important;
} */
.createdborder thead {
  color: #8b8b8b;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.createdborder .alltable{
  height: 240px;
  min-height: 240px;
  max-height: 240px;
  overflow: auto;
  padding-right: 10px;
}
.createdborder .vestingtable {
  height: unset;
  min-height: unset;
}
.offcanvas-start {
  width: 310px !important; 
}
.referralheading{
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 23px;
}
.ref_table thead {
  background: #c3c2c2;
}
.reftable thead{
  /* background: #e3e3e3; */
}
.CircularProgressbar-trail {
  /* stroke: #ababab !important; */
  stroke: #384c68a6 !important;
  /* background: url("../assets/storkepng.png") !important; */
}
.CircularProgressbar-path {
  stroke: rgb(108 188 53) !important;
}
.greentext{
  color: rgb(72 164 9);
  font-weight: 700;
}
.nclr{
  color: #fff;
}
.allnos{
  font-weight: 700;
  font-size: 15px;
}
.circlelast{
  border: 1px solid var(--themebtnclr);
  background-color: var(--themebtnclr);
  padding: 8px 6px;
  border-radius: 8px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.circlelist{
  /* position: relative; */
}
.circular-progress_tick{
background-color: rgb(108 188 53);
position: absolute;
left: 50%;
top: -5px;
transform: translateX(-50%);
border-radius: 50%;
padding: 7px 6px;
z-index: 2;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.circular-progress_tick svg{
  display: block;
}

.rank{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 12px;
  width: 45px;
  height: 45px;
  background: linear-gradient(180deg, #3E86D0 0%, #4E73DE 100%);
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  z-index: 2;
}
.refalltable{
  height: 180px;
  padding-right: 10px;
  min-height: 180px;
  max-height: 180px;
}
.notactive{
  padding: 4px 0px;
  font-weight: 600;
  font-size: 12px;
}
.weekly{
  font-size: 45px;
}
.upto{
  font-size: 42px;
}
.gen,.us{
  font-size: 20px;
}
.stake_name{
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  gap: 10px 30px;
}
.stake_name li{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.tailcoin{
  filter: drop-shadow(2px 2px 1px black);
}
.sideheadlabel{
  background: linear-gradient(90deg, #008244 0%, #0072EC 100%);
  border-radius: 7px;
  padding: 10px;
}
.sideheadlabel button{
  padding: 0px;
  font-weight: 400;
  font-size: 16px;
}
.sideheadlabel span{
  color: #fff;
  font-size: 16px;
}
.sideheadlabel .normalimg{
  width: 25px;
  object-fit: contain;
}
.gamecardsec{
  background: var(--sidebarbg);
  padding: 25px;
  border-radius: 10px;
  margin-top: 25px;
}
 .gamecard{
  background: #24262B !important;
  position: relative;
}
 .gamecard{
  display: block;
}
 .gamecard .card-title{
  text-align: center;
  padding: 5px 0px;
  font-family: 'Vendin';
}

 .gamecard .cardhoverlayer{
  position: absolute;
  z-index: 3;
  background: linear-gradient(90deg, rgba(0, 63, 130, 0.9) 0%, rgba(0, 114, 236, 0.9) 100%);
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;

}
 .gamecard .cardhoverlayer.active{
  opacity: 1;

}
 .gamecard .cardhoverlayer .playbtn{
  width: 60%;
  height: 18%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1800px) {
  /* .banner_listed {
    height: 320px;
    min-height: 320px;
} */
  .home_container{
    max-width: 85%;
  }
  .hom_head{
    font-size: 60px;
  }
  .f-12{
    font-size: 16px;
  }
  .blueice{
    width: 56%;
  }
  .bg_dice{
    height: 100vh;
  }
}

@media only screen and (min-width: 1400px) {
  .bg_dice{
    height: 100vh;
  }
  .blueice{
    width: 60%;
  }
  /* .cardss img {
    height: 210px !important;
    max-height: 210px !important;
    min-height: 210px !important;
} */
}

@media only screen and (min-width: 992px) and (max-width: 1951px) {
  .homemenumobile{
    display: none;
  }
  .mobileplay{
    display: none;
  }

  .sidebar {
    top: 50px !important;
  }
}

@media only screen and (min-width: 1200px) 
{
.mobileview{
  display: none;
}
/* .cardss img {
  height: 230px;
  max-height: 230px;
  min-height: 230px;
} */
}

@media only screen and (min-width: 1952px) 
{

  .homemenumobile{
    display: none;
  }
  .mobileplay{
    display: none;
  }
  .mobileview{
    display: none;
  }
  .sidebar {
    top: 50px !important;
  }
}

@media only screen and (min-width: 1800px) {
/* .cardss img {
  height: 260px !important;
  max-height: 260px !important;
  min-height: 260px !important;
} */
}

@media only screen and (max-width: 1199px) {
  .content_right{
    padding-left: 10px;
    padding-right: 12px;
  }
  .desktopview{
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .content_right{
    padding-left: 190px;
  }
  .leftsidebar{
    width: 180px;
  }
  /* .banner_listed {
    height: 260px;
    min-height: 260px;
} */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .circlelast {
    font-size: 10px;
  }
  .weekly {
    font-size: 35px;
}
.blueice{
  width: 80%;
}
}
@media only screen and (min-width: 992px) and (max-width: 1090px) {
/* .gameheader .header_alert__8Mnqu {
  padding: 2px 30px;
} */

}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blueice{
    width: 80%;
  }
  .gen, .us {
    font-size: 18px;
}
  .weekly {
    font-size: 26px;
}
.upto {
  font-size: 26px;
}
  .earntab .css-13cymwt-control {
    width: 100%;
    max-width: 75%;
}
  .referralticket {
    height: 120px;
    min-height: 120px;
    max-height: 120px;
}
.referralheading {
  font-size: 15px;
}
  label{
    font-size: 14px !important;
  }
  .contribution h6 {
    font-size: 12px;
  }
}


@media only screen and (max-width: 991px) {
 
  .listout{
    display: block;
  }
  .stakeborder {
    height: unset;
    min-height: unset;
    max-height: unset;
}
  .navmobile .nav-link {
      color: black;
      font-weight: 600;
  }
  .bottomcoin {
    bottom: 0%;
}
  .mobileburger{
    max-width: 40px;
    height: 40px;
    min-width: 40px;
    max-height: 40px;
    width: 100%;
  }
  .homemenudesktop{
    display: none;
  }
  /* .ptoken {
    font-size: 20px;
  }
  .mobileplay {
    font-size: 20px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 20px;
    margin-bottom: 6px;
} */
  .mobilenavs{
    text-align: center;
    margin-top: 2%;
  }
  .allcontest img{
    width: 20px;
  }
  .allcontest .btn-success {
    font-size: 13px;
}
  .wimg{
    /* filter: brightness(0.5); */
  }

  .css-b62m3t-container {
    width: 100%;
}
}

@media only screen and (max-width: 900px) {
  .sidebar {
    top: 85px !important;
  }
  .header_alert__8Mnqu {
    /* margin-top: 70px; */
    /* height: 85px; */
    text-align: center;
  }
  .header_alert__text__uomPs {
    flex-direction: column;
    gap: 5px;
  }

  .content {
    /* margin-left: 80px; */
    margin-top: 8%;
  }

  .dropdown-content {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 767px) {
  .blueice{
    width: 85%;
    margin: auto;
  }
  .liness{
    display: none !important;
  }
  .sidebar {
    top: 50px !important;
  }
/* .gameheader .header_alert__8Mnqu{
  padding: 2px 20px;
} */
/* .sidebar{
  top: 15% !important;
} */
.textshort {
  font-size: 12px;
}
}

@media only screen and (max-width: 640px) {
  .allbtns {
    text-align: left;
  }
  .textshort {
    font-size: 10px;
}
  .allbtns .mx-2 {
    margin-left: 0px !important;
  }

  .header_alert__text__uomPs {
    width: 100%;
    max-width: 320px;
  }

  /* .sidebar {
    top: 14% !important;
  } */
 .smart{
  font-size: 13px !important;
 }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .topcoin, .botcoin {
    width: 70px;
}
.socials {
  right: 5%;
  z-index: 9999999;
}
  .weekly {
    font-size: 36px;
}
  .earntab .css-13cymwt-control {
    width: 100%;
    max-width: 75%;
}
  .coingold {
    top: -16px;
  }
  .circlelast {
    font-size: 11px;
}
}

.mobileburger,.mimg{
  filter: invert(1);
}

@media only screen and (max-width: 575px) {
  .blueice{
    width: 80%;
  }
  .socials {
    right: 7%;
    top: 70%;
    z-index: 999999;
}
  .topcoin, .botcoin {
    width: 50px;
}
  .leftsidebar{
    top: 80px;
  }
  .rewardbg{
    background: #c8c8c8 !important;
    border-radius: 10px;
  }
  .howtoplay .f-14{
    font-size: 11px;
  }
  .gen,.us {
    font-size: 17px;
  }
  .weekly{
    font-size: 28px;
  }
  .upto{
    font-size: 28px;
  }
  .tri{
    display: none;
  }
  .dashboard p {
    font-size: 13px;
}
  .sidebar {
    top: 85px !important;
  }
  .earntab .justfun{
    display: unset !important;
  }
  .earntab .form-control {
    max-width: unset;
    margin-top: 10px;
}
  .stakeborder {
    padding: 20px 12px !important;
}
.stakeborder .nav-pills .nav-link {
  font-size: 14px;
  padding: 10px 10px;
}
  .textshort{
    font-size: 13px;
  }
  .piecharts ul p:nth-child(1) {
    font-size: 13px;
}
.piecharts ul p:nth-child(2) {
  font-size: 13px;
}
  /* .sidebar {
    top: 15% !important;
} */
  .owlcarousel .item {
    background: unset;
    /* background-color: #e3e3e3; */
}
  .circlelast {
    font-size: 11px;
}
  .heightborder {
    height: unset;
    max-height: unset;
    min-height: unset;
}
  .walletss {
    font-size: 15px !important;
}
 .walletsss {
  font-size: 13px !important;
}
  /* .gameheader .header_alert__8Mnqu{
    padding: 2px 18px;
  } */
  .bottomborder {
    padding: 10px 5px;
}
  .play,.dicename{
    font-size: 12px;
  }
  .Multi{
    font-size: 12px;
  }
  .styles_dice {
    grid-gap: 9px !important;
    gap: 9px !important;
}
.dice_box {
  width: 58px !important;
  height: 58px !important;
}
  .bet {
    font-size: 13px;
  }
  .footer .row {
    text-align: center;
  }
  .table>thead {
    font-size: 13px;
  }
  tbody {
    font-size: 13px;
  }
  .btn-light {
    font-size: 12px;
}
}

@media only screen and (max-width: 400px) {
  .header_alert__8Mnqu {
    font-size: 10px;
  }
}

.table_gme th:first-child,.table_gme td:first-child
{
  min-width: 100px;
}

@keyframes rotate-bg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.borders_full_hei_show
{
  align-items: center;
}
.borders_full_hei_show p
{
  word-break: break-all;
}
 
.pos_stargery_card
{
  border: 1px solid #a2a2a2;
    background-color: #a2a2a26b;
    border-radius: 6px;
    padding: 4px 8px;
    color: black;
    max-width: 250px;
}

.badge_grn
{
  background-color: #2b6947;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
}

.badge_rd
{
  background-color: #933364;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
}

.intimate_card_green
{
  background-color:#2c6749d4;
  padding: 5px 10px 0px 10px;
  border-radius: 10px;
  color:#fff;
  font-size: 12px;
}
.intimate_card_red
{
  background-color:#933364ba;
  padding: 5px 10px;
  border-radius: 10px;
  color:#fff;
  font-size: 12px;

}

.img_cin_stra
{
  max-width: 25px;
  max-height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin-left: 10px;
}
.text-right
{
  text-align: right;
}

.prog_bar_hide {
  width: 100%;
  background-color: grey;
  margin-top: 3px;
}

.myBar {
  width: 100%;
  height: 5px;
  background-color: #ffa60e;
}
.hide_ele_div
{
  transform: translateX(-200%);
  transition: all 0.5s ease-in;
  
}
.btn_tooltip_othng
{
  background-color: transparent !important;
  border:none !important;
}
.flex_rto_div
{
  display: flex;
  align-items: center;
}
@media only screen and (max-width:575px)
{
  .border_div_wrp
  {
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start !important;
  }
}



@media only screen and (min-width:768px) and (max-width:1499px)
{
  .border_div_wrp
  {
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start !important;
  }
}
.border_div_wrp_txt
{
  word-break: break-all;
}
@media only screen and (max-width:767px)
{
  .pos_stargery_card
  {
    margin: 20px auto;
  }
}
.pos_stargery_card
{
  margin-top: 20px;
  margin-bottom: 20px;
}
.spin_rollbtn:disabled{
  opacity: .8;
}

.walletss_sm.walletss
{
  font-size: 13px !important;
  border-color: transparent;
}

.gametable_width th:first-child,
.gametable_width td:first-child
{
  min-width: 120px;
}
.td_with_img
{
  display: flex;
  align-items: center;

}
.td_with_img img
{
  margin-left: 5px;
  margin-top: 0px !important;
}

.gametable_width th:nth-child(2),
.gametable_width td:nth-child(2)
{
  min-width: 200px;
}

.gametable_width th:nth-child(4),
.gametable_width td:nth-child(4)
{
  min-width: 150px;
}
.gametable_width th:nth-child(5),
.gametable_width td:nth-child(5)
{
  min-width: 200px;
}


.gametable_width th:last-child,
.gametable_width td:last-child
{
  min-width: 200px;
}


@keyframes animbannertext {
  0%,100% {
    transform: scale(0.7) translate(-40%,-50%);
  }
  50% {
    transform: scale(0.6) translate(-50%,-50%);
  }
  
}
@media screen and (max-width:767px) {
  .banner_listed{
    background-size: cover;
    background-position: center;
  }
  .banner_listed .bannertext{
    left: 32%;
  }
}
.customcontainer{
  max-width: 90%;
}

@media screen and (max-width:1199px) {
  .leftsidebar{
    background: none;
    padding: 0px;
  }
  
}